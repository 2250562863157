import { MiddlewareAPI, Dispatch } from 'redux';
import * as Actions from "./Actions";

import * as paths from "../PagePathConstants";

import Auth from "./Backend/Auth/Auth";
import {TJDataBase} from "./Backend/DataBase/TJDataBase";
import {IJot, JotId, UpdateHandler} from "./DataInterfaces";
import {ActionTypes} from "./Actions";
import {AppState} from "./DataInterfaces";
import {HashtagFeedId, MainFeedId, ProfileFeedId, SourceFeedId} from "./Reducer";

class BackendMiddlewareState
{
    readonly MainFeedJotUpdateKey = "MainFeedJotUpdateKey";
    readonly ProfileFeedJotUpdateKey = "ProfileFeedJotUpdateKey";
    readonly SourceFeedJotUpdateKey = "SourceFeedJotUpdateKey";
    readonly HashtagFeedJotUpdateKey = "HashtagFeedJotUpdateKey";
    readonly UserProfileDetailsUpdateKey = "UserProfileDetailsUpdateKey"

    private updateHandles = new Map<string, UpdateHandler>();

    setUpdateHandle(key: string, updateHandle: UpdateHandler)
    {
        // Clean up old handle
        this.updateHandles.get(key)?.cleanup();

        if (updateHandle)
        {
            this.updateHandles.set(key, updateHandle);
        }
    }

    handlePopulated(key: string)
    {
        return this.updateHandles.has(key);
    }

    currentHandleContainsRange(key: string, timeNewestBound: Actions.Timestamp, timeOldestBound: Actions.Timestamp)
    {
        return this.updateHandles.get(key)?.isTimeRangeContained(timeNewestBound, timeOldestBound) ?? false
    }

    clearHandles(predicate: ((key: string) => boolean) = (_) => {return true;} )
    {
        this.updateHandles.forEach((handle, key) => {
            if (predicate(key))
            {
                handle.cleanup();
                this.updateHandles.delete(key);
            }
        });
    }
}

const middlewareState = new BackendMiddlewareState();

function fetchFeedJots(feedJotIds: Map<JotId, JotId>, state: AppState)
{
    let jots: IJot[] = [];

    feedJotIds.forEach(value => {
        const jot = state.jots.get(value)
        if (jot)
        {
            jots = [...jots, jot]
        }
    })

    return jots
}

const MainFeedInitialJotCount = 20;
const SecondaryFeedInitialJotCount = 10;

const BackendMiddleware = (api: MiddlewareAPI<Dispatch<ActionTypes>, AppState>) => {
    return (next: Dispatch<ActionTypes>) => (action: ActionTypes) => {
        switch (action.type) {
            case Actions.USER_LOGOUT: {
                middlewareState.clearHandles();

                Auth.logout();
                return next(action);
            }
            case Actions.ROUTE_LOCATION_DID_UPDATE: {
                // The page actions should trigger the listeners to be setup after this call
                const currentPath = action.location.pathname;

                middlewareState.clearHandles((key) => key !== middlewareState.UserProfileDetailsUpdateKey);

                const startOfPath = currentPath.split('/')[1];
                switch (`/${startOfPath}`)
                {
                    case paths.MainPagePath: {
                        if (!middlewareState.handlePopulated(middlewareState.MainFeedJotUpdateKey))
                        {
                            middlewareState.setUpdateHandle(middlewareState.MainFeedJotUpdateKey, TJDataBase.fetchMainFeedJots(next, api, MainFeedInitialJotCount))
                        }
                        break;
                    }

                    case paths.ProfilePage: {
                        let userID = currentPath.split('/')[2];
                        const currentUserID = Auth.currentUserUID()
                        if (!currentUserID)
                        {
                            return
                        }

                        if (!userID)
                        {
                            userID = currentUserID;
                        }

                        TJDataBase.geUserDetails(next, userID)

                        middlewareState.setUpdateHandle(middlewareState.ProfileFeedJotUpdateKey, TJDataBase.fetchUserCreatedJots(userID, next, SecondaryFeedInitialJotCount))

                        break;
                    }

                    case paths.SourceJotsPage: {
                        let source = currentPath.split('/')[2];

                        middlewareState.setUpdateHandle(middlewareState.SourceFeedJotUpdateKey, TJDataBase.fetchSourceJots(source, next, SecondaryFeedInitialJotCount))

                        break;
                    }
                    case paths.HashtagJotsPage: {
                        let hashtagId = currentPath.split('/')[2];

                        middlewareState.setUpdateHandle(middlewareState.HashtagFeedJotUpdateKey, TJDataBase.fetchHashtagJots(hashtagId, next, SecondaryFeedInitialJotCount))

                        break;
                    }
                }
                return next(action);
            }
            case Actions.FETCH_USER_DATA: {
                return next(action);
            }
            case Actions.FETCH_PREDICTIONS: {
                if (!middlewareState.handlePopulated(middlewareState.MainFeedJotUpdateKey))
                {
                    middlewareState.setUpdateHandle(middlewareState.MainFeedJotUpdateKey, TJDataBase.fetchMainFeedJots(next, api, MainFeedInitialJotCount))
                }

                return next(action);
            }
            case Actions.MAIN_FEED_FETCH_MORE_JOTS: {
                const state = api.getState()
                const lookupId = MainFeedId

                const feedJotIds = state.feedJotIds.get(lookupId)

                if (feedJotIds) {
                    const jots = fetchFeedJots(feedJotIds, state)

                    if (jots.length > 0) {
                        const lastInsertedValue: IJot = Array.from(jots.values()).pop() as IJot;
                        middlewareState.setUpdateHandle(middlewareState.MainFeedJotUpdateKey, TJDataBase.fetchMainFeedJots(next,
                                                                                                                                api,
                                                                                                                                action.jotCount,
                                                                                                                                lastInsertedValue.createdTimestamp));
                    }
                }
                return next(action);
            }
            case Actions.MAIN_FEED_LIVE_UPDATE_POSITION: {
                if (middlewareState.currentHandleContainsRange(middlewareState.MainFeedJotUpdateKey, action.newestTimestamp, action.oldestTimestamp)) {return next(action)}

                const state = api.getState()
                const feedJotIds = state.feedJotIds.get(MainFeedId)

                if (feedJotIds) {
                    const jots = fetchFeedJots(feedJotIds, state)

                    // We don't want to rebuild the live updating jots unless the user has scrolled far enough to refresh jots
                    if (MainFeedInitialJotCount >= jots.length) {
                        return next(action);
                    }


                    // const mostRecentJot = jots[0]

                    // if (jotsWeHave[0].id === mostRecentJot.id) return next(action);

                    const jotsToUpdate = SecondaryFeedInitialJotCount;
                    middlewareState.setUpdateHandle(middlewareState.MainFeedJotUpdateKey, TJDataBase.fetchMainFeedJots(
                        next,
                        api,
                        jotsToUpdate,
                        action.newestTimestamp,
                        action.oldestTimestamp));
                }

                return next(action);
            }
            case Actions.PROFILE_FEED_FETCH_MORE_JOTS: {
                const state = api.getState()
                const lookupId = ProfileFeedId + action.userId

                const feedJotIds = state.feedJotIds.get(lookupId)
                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)
                    const profileUserId = action.userId

                    if(jots.length > 0)
                    {
                        const lastInsertedValue: IJot = jots.pop() as IJot;
                        middlewareState.setUpdateHandle(middlewareState.ProfileFeedJotUpdateKey, TJDataBase.fetchUserCreatedJots(profileUserId, next, action.jotCount, lastInsertedValue.createdTimestamp))
                    }
                }
                return next(action);
            }
            case Actions.PROFILE_FEED_LIVE_UPDATE_POSITION: {
                if (middlewareState.currentHandleContainsRange(middlewareState.ProfileFeedJotUpdateKey, action.newestTimestamp, action.oldestTimestamp)) {return next(action)}

                const state = api.getState()
                const lookupId = ProfileFeedId + action.userId

                const feedJotIds = state.feedJotIds.get(lookupId)

                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)

                    if(SecondaryFeedInitialJotCount >= jots.length) { return next(action);}
                    if (!action.userId || action.userId === "") return next(action)

                    if(jots.length > 0)
                    {
                        const maxAmountOfJots = 30;
                        const profileUserId = action.userId
                        middlewareState.setUpdateHandle(middlewareState.ProfileFeedJotUpdateKey, TJDataBase.fetchUserCreatedJots(profileUserId, next, maxAmountOfJots, action.newestTimestamp, action.oldestTimestamp))
                    }
                }

                return next(action)
            }
            case Actions.SOURCE_FEED_FETCH_MORE_JOTS: {
                const state = api.getState()
                const lookupId = SourceFeedId +  action.sourceId

                const feedJotIds = state.feedJotIds.get(lookupId)
                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)
                    const sourceId = action.sourceId

                    if(jots.length > 0)
                    {
                        const lastInsertedValue: IJot = jots.pop() as IJot;
                        middlewareState.setUpdateHandle(middlewareState.SourceFeedJotUpdateKey, TJDataBase.fetchSourceJots(sourceId, next, action.jotCount, lastInsertedValue.createdTimestamp))
                    }
                }
                return next(action);
            }
            case Actions.SOURCE_FEED_LIVE_UPDATE_POSITION: {
                if (middlewareState.currentHandleContainsRange(middlewareState.SourceFeedJotUpdateKey, action.newestTimestamp, action.oldestTimestamp)) {return next(action)}

                const state = api.getState()
                const lookupId = SourceFeedId + action.sourceId

                const feedJotIds = state.feedJotIds.get(lookupId)

                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)

                    if(SecondaryFeedInitialJotCount >= jots.length) { return next(action);}
                    if (!action.sourceId || action.sourceId === "") return next(action)

                    if(jots.length > 0)
                    {
                        const maxAmountOfJots = 30;
                        const sourceId = action.sourceId
                        middlewareState.setUpdateHandle(middlewareState.SourceFeedJotUpdateKey, TJDataBase.fetchSourceJots(sourceId, next, maxAmountOfJots, action.newestTimestamp, action.oldestTimestamp))
                    }
                }

                return next(action)
            }
            //////
            case Actions.HASHTAG_FEED_FETCH_MORE_JOTS: {
                const state = api.getState()
                const lookupId = HashtagFeedId +  action.hashtagId

                const feedJotIds = state.feedJotIds.get(lookupId)
                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)
                    const hashtagId = action.hashtagId

                    if(jots.length > 0)
                    {
                        const lastInsertedValue: IJot = jots.pop() as IJot;
                        middlewareState.setUpdateHandle(middlewareState.SourceFeedJotUpdateKey, TJDataBase.fetchHashtagJots(hashtagId, next, action.jotCount, lastInsertedValue.createdTimestamp))
                    }
                }
                return next(action);
            }
            case Actions.HASHTAG_FEED_LIVE_UPDATE_POSITION: {
                if (middlewareState.currentHandleContainsRange(middlewareState.HashtagFeedJotUpdateKey, action.newestTimestamp, action.oldestTimestamp)) {return next(action)}

                const state = api.getState()
                const lookupId = HashtagFeedId + action.hashtagId

                const feedJotIds = state.feedJotIds.get(lookupId)

                if (feedJotIds)
                {
                    const jots = fetchFeedJots(feedJotIds, state)

                    if(SecondaryFeedInitialJotCount >= jots.length) { return next(action);}
                    if (!action.hashtagId || action.hashtagId === "") return next(action)

                    if(jots.length > 0)
                    {
                        const maxAmountOfJots = 30;
                        const hashtagId = action.hashtagId
                        middlewareState.setUpdateHandle(middlewareState.HashtagFeedJotUpdateKey, TJDataBase.fetchHashtagJots(hashtagId, next, maxAmountOfJots, action.newestTimestamp, action.oldestTimestamp))
                    }
                }

                return next(action)
            }
                //////
            case Actions.CURRENT_USER_CREATED_JOTS: {
                return next(action);
            }
            case Actions.Current_USER_DETAILS: {
                if (!middlewareState.handlePopulated(middlewareState.UserProfileDetailsUpdateKey))
                {
                    middlewareState.setUpdateHandle(middlewareState.UserProfileDetailsUpdateKey, TJDataBase.getCurrentUserUpdates(next))
                }

                return next(action);
            }
            case Actions.USER_ADDED_PREDICTION: {
                middlewareState.setUpdateHandle(middlewareState.MainFeedJotUpdateKey, TJDataBase.fetchMainFeedJots(next, api, MainFeedInitialJotCount))
                return next(action);
            }
            case Actions.USER_AGREED_WITH_PREDICTION: {
                TJDataBase.agreeJot(action.jotId)
                return next(action);
            }
            case Actions.USER_DISAGREED_WITH_PREDICTION: {
                TJDataBase.disagreeJot(action.jotId)
                return next(action);
            }
            case Actions.USER_MARKED_JOT_TRUE: {
                TJDataBase.markJotTrue(action.jotId, action.resultKnownTimestamp)
                return next(action);
            }
            case Actions.USER_MARKED_JOT_FALSE: {
                TJDataBase.markJotFalse(action.jotId, action.resultKnownTimestamp)
                return next(action);
            }
            case Actions.ADD_SOURCE: {
                return next(action);
            }
            case Actions.GET_SOURCE_MATCHES: {
                TJDataBase.getSourceMatches(action.sourceName, next)
                return next(action);
            }
            case Actions.GET_HASHTAG_MATCHES: {
                TJDataBase.getHashtagMatches(action.hashtagName, next)
                return next(action);
            }
        }
        return next(action);
    };
};

export default BackendMiddleware;
