import './HashtagJots.css';

import React from 'react';
import {connect} from "react-redux";
import {AppState} from "../../Store/DataInterfaces";
import * as Actions from "../../Store/Actions";
import GenericCreationFeed from '../../GenericUI/GenericCreationFeed/GenericCreationFeed';

interface IProps {
    selectedHashtag: string
    dispatch: any;
}

const HashtagJots = (props: IProps) => {
    return (
        <GenericCreationFeed pageName={"Hashtag: " + props.selectedHashtag}
                             moreJots={(jotCount: number) => props.dispatch({
                                 type: Actions.HASHTAG_FEED_FETCH_MORE_JOTS,
                                 hashtagId: props.selectedHashtag,
                                 jotCount: jotCount
                             })}
                             refreshJots={(newTimestamp: Actions.Timestamp, oldTimestamp: Actions.Timestamp) => props.dispatch({
                                 type: Actions.HASHTAG_FEED_LIVE_UPDATE_POSITION,
                                 hashtagId: props.selectedHashtag,
                                 newestTimestamp: newTimestamp,
                                 oldestTimestamp: oldTimestamp
                             })}/>
    );
};

const mapStateToProps = (state: AppState) => {
    interface stateToProps {
        selectedHashtag: string
    }

    let mapObject: stateToProps = {
        selectedHashtag: state.selectedHashtag
    }

    return mapObject;
};

export default connect(mapStateToProps)(HashtagJots);
