import './MainFeed.css';

import React from 'react';
import {connect} from "react-redux";
import GenericCreationFeed from "../../GenericUI/GenericCreationFeed/GenericCreationFeed";
import * as Actions from "../../Store/Actions";

interface IProps {
    dispatch: any;
}

const MainFeed = (props: IProps) => {

    return (
        <GenericCreationFeed pageName={"Prediction Playground"}
                             moreJots={(jotCount: number) => props.dispatch({
                                 type: Actions.MAIN_FEED_FETCH_MORE_JOTS,
                                 jotCount: jotCount
                             })}
                             refreshJots={(newTimestamp: Actions.Timestamp, oldTimestamp: Actions.Timestamp) => props.dispatch({
                                 type: Actions.MAIN_FEED_LIVE_UPDATE_POSITION, newestTimestamp: newTimestamp,
                                 oldestTimestamp: oldTimestamp
                             })}/>
    );
}

export default connect()(MainFeed);
