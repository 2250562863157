import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseAuth = firebase.auth();
// if (process.env.NODE_ENV === "development") {
//     firebaseAuth.useEmulator("http://localhost:9099");
// }

class Auth {
    static validEmail(email: string | null) {
        if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return (true)
        }
        // TODO: Remove this is just testing
        // alert("You have entered an invalid email address!")
        return (false)
    };

    static validPassword(password: string | null) {
        if (password && password.length >= 6)
        {
            return (true)
        }
        // TODO: Remove this is just testing
        // alert("You have entered an invalid password, a password must be at least 6 characters long")
        return (false)
    };

    static signUp(email: string | null, password: string | null) {
        if (!(email && password) || !this.validEmail(email) || !this.validPassword(password)) {
            return
        }

        firebaseAuth.createUserWithEmailAndPassword(email, password).then(credentials => {
            // TODO: Remove just for testing
            console.log(credentials);
        });
    };

    static async logIn(email: string | null, password: string | null) {
        if (!(email && password) || !this.validEmail(email) || !this.validPassword(password)) {
            throw new Error('Invalid email or password');
        }

        return firebaseAuth.signInWithEmailAndPassword(email, password);
    };

    static logout() {
        firebaseAuth.signOut().catch((e) => {
            if (e)
            {
                console.log(e)
            }
        })
    }

    static userStateChangedCallback(callback: (user: any) => void)  {
        firebaseAuth.onAuthStateChanged(user => {callback(user)});
    }

    static userLoggedIn() {
        return !!firebaseAuth.currentUser;
    }

    static currentUserUID = () => {
        const user = firebaseAuth.currentUser;
        if (!user)
        {
            return undefined;
        }
        return user.uid;
    }

    static currentUserEmail = () => {
        const user = firebaseAuth.currentUser;
        if (!user)
        {
            return undefined;
        }
        return user.email;
    }
}

export default Auth;
