import React, {useEffect} from 'react';
import { Route } from "react-router-dom"
import {connect} from "react-redux";

import * as actionTypes from "./Store/Actions";
import Auth from './Store/Backend/Auth/Auth';

import MenuBar from "./Menu/MenuBar";
import Startup from "./Pages/Startup/Startup";
import Welcome from "./Pages/Welcome/Welcome";
import UserSignupDetails from "./Pages/UserSignUpDetails/UserSignUpDetails";
import YourJots from "./Pages/MainFeed/MainFeed";
import Profile from "./Pages/Profile/Profile";
import WhatsNew from "./Pages/WhatsNew/WhatsNew";
import Settings from "./Pages/Settings/Settings";
import SourceJots from "./Pages/SourceJots/SourceJots";
import HashtagJots from "./Pages/HashtagJots/HashtagJots";
import Support from "./Pages/Support/Support";

import * as paths from "./PagePathConstants";

import './App.css';
import {TJDataBase} from "./Store/Backend/DataBase/TJDataBase";
import * as ActionFactory from "./Store/ActionFactory";
import {AppState, IUserDetails} from "./Store/DataInterfaces";
import {analytics} from "./Store/Backend/BackendSetup";
import {setUserProperties} from "firebase/analytics";

const App = (props: any) => {

    // @ts-ignore
    const setupFeedbackData = () => {
        // @ts-ignore
        if(window.doorbellOptions) window.doorbellOptions.email = props.currentUserEmail;
        // @ts-ignore
        if(window.doorbellOptions) window.doorbellOptions.name = props.currentUserName;
        // @ts-ignore
        if(typeof doorbell !== 'undefined') doorbell.refresh();
    }

    Auth.userStateChangedCallback(user => {

        const isCurrentPage = (pagePath: string) => {
            return props.currentRootPath === pagePath
        }

        if(user && !props.userLoggedInState)
        {
            // TODO: We should request the user data and put it in the store here. Not query it our selves
            return TJDataBase.fetchCurrentUserData().then(function(userDetails) {
                if (!!userDetails && userDetails.userName !== "")
                {
                    props.storeCurrentUserDetails(userDetails);
                    props.markUserAsLoggedIn();

                    // Setup user analytics
                    setUserProperties(analytics, {BetaTestingOnlyUserID: `(${userDetails.userName})-${userDetails.userId}`});

                    if (isCurrentPage("/") ||isCurrentPage(paths.SignUpPage) || isCurrentPage(paths.WelcomePage))
                    {
                        props.history.push(paths.MainPagePath);
                    }
                }
                else
                {
                    if (!isCurrentPage(paths.SignUpPage))
                    {
                        props.history.push(paths.SignUpPage);
                    }
                }
            }).then(function () {
                props.routeUpdate(props.history.location)

                // @ts-ignore
                if(window.doorbellOptions && typeof doorbell !== 'undefined')
                {
                    // @ts-ignore
                    window.doorbellOptions.hideEmail = true;
                    // @ts-ignore
                    doorbell.refresh();
                }

            });
        }
        else if(!user)
        {
            // @ts-ignore
            if(window.doorbellOptions && typeof doorbell !== 'undefined')
            {
                // @ts-ignore
                window.doorbellOptions.hideEmail = false;
                // @ts-ignore
                doorbell.refresh();
            }

            props.history.push(paths.WelcomePage);
        }
    });

    useEffect(setupFeedbackData, [props.currentUserEmail, props.currentUserName]);

  return (
              <div className="App">
                  <MenuBar history={props.history}>
                  </MenuBar>
                  <div id={"page-content-wrapper"}>
                      <Route path={paths.RootPage} exact component={Startup}/>
                      <Route path={paths.WelcomePage} exact component={Welcome}/>
                      <Route path={paths.SignUpPage} exact component={UserSignupDetails}/>
                      <Route path={paths.MainPagePath} exact component={YourJots}/>
                      <Route path={paths.ProfilePage} component={Profile}/>
                      <Route path={paths.WhatsNewPage} exact component={WhatsNew}/>
                      <Route path={paths.SettingsPage} exact component={Settings}/>
                      <Route path={paths.SourceJotsPage} component={SourceJots}/>
                      <Route path={paths.HashtagJotsPage} component={HashtagJots}/>
                      <Route path={paths.SupportPage} exact component={Support}/>
                  </div>
              </div>
  );
}

const mapStateToProps = (state: AppState) => {

    let stateProps = {
        currentRootPath: state.currentRootPath,
        currentUserName: "",
        currentUserEmail: "",
        userLoggedInState: state.userLoggedIn
    }

    const userDetails =  state.users.get(state.currentUserID)
    if (userDetails)
    {
        stateProps.currentUserName = userDetails.userName;
        const email = userDetails.email;
        if (email)
        {
            stateProps.currentUserEmail = email;
        }
    }

    return stateProps;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        markUserAsLoggedIn: () => dispatch(ActionFactory.UserLogin()),
        storeCurrentUserDetails: (userDetails: IUserDetails) => dispatch(ActionFactory.currentUserDetails(userDetails)),
        routeUpdate: (location: string) => dispatch({type: actionTypes.ROUTE_LOCATION_DID_UPDATE, location: location}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
