import firebase from 'firebase/compat/app';
import "firebase/compat/firestore"

import Auth from "../Auth/Auth";
import {ISuggestion, IJot, IUserDetails, UpdateHandler} from "../../DataInterfaces";
import {Action, Dispatch, MiddlewareAPI} from "redux";
import * as actionTypes from "../../Actions";
import {userDetails, currentUserDetails, BackendCurrentUserVotes} from "../../ActionFactory";
import * as Actions from "../../Actions";
import {ActionTypes} from "../../Actions";
import assert from "assert";
import {analytics} from "../BackendSetup";
import {logEvent } from "firebase/analytics";

const {Counter} = require("../../../BackendUtil/sharded-counter-helper.js.ts");
const FlexSearch = require("flexsearch");

let db = firebase.firestore();
// if (process.env.NODE_ENV === "development") {
//     db.useEmulator("localhost", 8080);
//     // firebase.functions().useEmulator("localhost", 5001);
// }

const USERCOLLECTION = "Users";
const PREDICTIONS = "Predictions";
const HASHTAGS = "Hashtags";
const SOURCES = "Sources";
const CreatedPredictions = "Created_Predictions";
const CreatedJots = "Created_Jots";
const MarkedPredictions = "Marked_Predictions";
const PredictionsVotedOn = "Predictions_Voted_On";
const VOTERS = "Voters";
const MARKED = "Marked";
const RESULT = "result";

const AGREED = "Agreed"
const DISAGREED = "Disagreed"

class _TJDataBase {
    /// This function tries to get all user details it can for a user based on the current logged-in users permissions
    static tryObtainCompleteUserDetail(userID: string, data: any) {
        let localUserDetails: IUserDetails =
            {
                userName: "",
                userId: userID ? userID : "",
                score: 0,
                bio: "",
            }

        const currentUser = Auth.currentUserUID();
        if (currentUser === userID) {
            const email = Auth.currentUserEmail()
            if (email) {
                localUserDetails["email"] = email;
            }
        }

        if (!!data) {
            if (!!data["userName"]) {
                localUserDetails["userName"] = data["userName"];
            }

            if (!!data["userScore"]) {
                localUserDetails["score"] = data["userScore"];
            }

            if (!!data.bio) {
                localUserDetails.bio = data.bio;
            }

            if (data.correctVoteCount) {
                localUserDetails.correctVoteCount = data.correctVoteCount
            }

            if (data.wrongVoteCount) {
                localUserDetails.wrongVoteCount = data.wrongVoteCount
            }
        }

        return localUserDetails;
    }

    private static convertToTypedJots(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
        let jots = new Array<IJot>();

        querySnapshot.forEach(function (doc) {
            if (!!doc.data()) {
                jots = [...jots, {
                    creator: doc.data().creator,
                    creatorName: doc.data().creatorName,
                    text: doc.data().text,
                    agree: doc.data().agree,
                    disagree: doc.data().disagree,
                    createdTimestamp: doc.data().createdTimestamp,
                    outcome: doc.data().outcome,
                    predictionClosed: doc.data().predictionClosed,
                    closedTimestamp: doc.data().closedTimestamp,
                    markingUser: doc.data().markingUser,
                    resultKnownTimestamp: doc.data().resultKnownTimestamp,
                    id: doc.id,
                    source: doc.data().source
                }];
            }
        });

        return jots;
    }

    private static async injectJotDataIntoAppState(dispatch: Dispatch<Action>,
                                                   querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
        let jots = this.convertToTypedJots(querySnapshot);

        await this.injectVotesAssociatedWithJotsIntoAppState(dispatch, jots);

        return jots
    }

    private static async injectVotesAssociatedWithJotsIntoAppState(dispatch: Dispatch<Action>, jots: IJot[]) {
        const userID = Auth.currentUserUID();
        let votes = new Map();

        for (const jot of jots) {
            await db.collection(USERCOLLECTION)
                .doc(userID)
                .collection(PredictionsVotedOn)
                .doc(jot.id)
                .get().then(function (doc) {
                    if (doc.exists) {
                        votes.set(doc.id, doc.get("Voted") === AGREED)
                    }
                });
        }

        dispatch(BackendCurrentUserVotes(votes));
    }

    static geUserDetails(dispatch: Dispatch<Action>, userID: string) {
        if (userID === "" || userID === undefined) {
            return;
        }

        const docRef = db.collection(USERCOLLECTION).doc(userID);

        return docRef.get().then(function (doc) {
            if (doc.exists && doc.data()) {
                const userDetail = TJDataBase.tryObtainCompleteUserDetail(doc.id, doc.data())
                dispatch(userDetails(userDetail));
            }
        })
    }

    static getCurrentUserUpdates(dispatch: Dispatch<Action>) {
        const userID = Auth.currentUserUID();
        const docRef = db.collection(USERCOLLECTION).doc(userID);

        return new UpdateHandler(docRef.onSnapshot(function (doc) {
            if (doc.exists && doc.data()) {
                const userDetail = TJDataBase.tryObtainCompleteUserDetail(doc.id, doc.data())
                dispatch(currentUserDetails(userDetail));
            }
        }))
    }

    static fetchCurrentUserData() {
        const userID = Auth.currentUserUID();
        const docRef = db.collection(USERCOLLECTION).doc(userID);
        return docRef.get().then(function (doc) {
            return TJDataBase.tryObtainCompleteUserDetail(doc.id, doc.data())
        })
    }

    static fetchMainFeedJots(dispatch: Dispatch<ActionTypes>,
                             api: MiddlewareAPI<Dispatch<ActionTypes>>,
                             jotCount: number,
                             newestJotTimestamp?: firebase.firestore.Timestamp,
                             oldestJotTimestamp?: firebase.firestore.Timestamp) {
        const predictionsRef = db.collection(PREDICTIONS);
        const FeedInitialCount = 20;

        if (newestJotTimestamp && oldestJotTimestamp) {
            // We are fetching jots after the first load
            return new UpdateHandler(predictionsRef.orderBy("createdTimestamp", "desc")
                    .startAt(newestJotTimestamp)
                    .endAt(oldestJotTimestamp)
                    .limit(30)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: actionTypes.MAIN_FEED_JOTS_RETURNED, jots: jots});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotTimestamp,
                oldestJotTimestamp)
        }


        if (newestJotTimestamp) {
            // We are fetching jots after the first load
            return new UpdateHandler(predictionsRef.orderBy("createdTimestamp", "desc")
                    .startAt(newestJotTimestamp)
                    .limit(10)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: actionTypes.MAIN_FEED_JOTS_RETURNED, jots: jots});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotTimestamp)
        }

        // We are fetching the first jot load
        const liveListenLimit = FeedInitialCount;
        return new UpdateHandler(predictionsRef.orderBy("createdTimestamp", "desc")
            .limit(liveListenLimit)
            .onSnapshot(function (querySnapshot) {
                    _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                        if (jots.length) {
                            dispatch({type: actionTypes.MAIN_FEED_JOTS_RETURNED, jots: jots});
                        }
                    })
                },
                function (error) {
                    console.log("fetch predictions: " + error)
                }))
    }

    static fetchUserCreatedJots(userID: string,
                                dispatch: Dispatch<Action>,
                                jotCount: number,
                                newestJotId?: firebase.firestore.Timestamp,
                                oldestJotId?: firebase.firestore.Timestamp) {
        const predictionsRef = db.collection(PREDICTIONS);

        if (newestJotId && oldestJotId) {
            return new UpdateHandler(predictionsRef.where("creator", "==", userID)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId)
                    .endAt(oldestJotId)
                    .limit(30)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.CURRENT_USER_CREATED_JOTS, jots: jots, userId: userID});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId,
                oldestJotId)
        }

        if (newestJotId) {
            return new UpdateHandler(predictionsRef.where("creator", "==", userID)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId).limit(jotCount)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.CURRENT_USER_CREATED_JOTS, jots: jots, userId: userID});
                                }
                            })


                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId)
        }


        return new UpdateHandler(predictionsRef.where("creator", "==", userID)
            .orderBy("createdTimestamp", "desc")
            .limit(jotCount)
            .onSnapshot(function (querySnapshot) {
                    _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                        if (jots.length) {
                            dispatch({type: Actions.CURRENT_USER_CREATED_JOTS, jots: jots, userId: userID});
                        }
                    })
                },
                function (error) {
                    console.log("fetch predictions: " + error)
                }))

    }

    static fetchSourceJots(source: string,
                           dispatch: Dispatch<Action>,
                           jotCount: number,
                           newestJotId?: firebase.firestore.Timestamp,
                           oldestJotId?: firebase.firestore.Timestamp) {
        const predictionsRef = db.collection(PREDICTIONS);

        if (newestJotId && oldestJotId) {
            return new UpdateHandler(predictionsRef.where("source", "==", source)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId)
                    .endAt(oldestJotId)
                    .limit(30)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.SOURCE_JOTS, jots: jots, source: source});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId,
                oldestJotId)
        }

        if (newestJotId) {
            return new UpdateHandler(predictionsRef.where("source", "==", source)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId).limit(jotCount)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.SOURCE_JOTS, jots: jots, source: source});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId)
        }

        return new UpdateHandler(predictionsRef.where("source", "==", source)
            .orderBy("createdTimestamp", "desc")
            .limit(jotCount)
            .onSnapshot(function (querySnapshot) {
                    _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                        if (jots.length) {
                            dispatch({type: Actions.SOURCE_JOTS, jots: jots, source: source});
                        }
                    })
                },
                function (error) {
                    console.log("fetch predictions: " + error)
                }))

    }

    static uploadUserProfile(userDetails: IUserDetails) {
        const userID = Auth.currentUserUID();

        return db.collection(USERCOLLECTION).doc(userID).set({
                userName: userDetails.userName,
                createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                profileSetup: true
            }
        ).catch(function (error) {
            console.log("Error setting user details:", error);
        });
    }

    static fetchHashtagJots(hashtag: string,
                            dispatch: Dispatch<Action>,
                            jotCount: number,
                            newestJotId?: firebase.firestore.Timestamp,
                            oldestJotId?: firebase.firestore.Timestamp) {
        const predictionsRef = db.collection(PREDICTIONS);

        const hashtagUpperCase = hashtag.toUpperCase()
        if (newestJotId && oldestJotId) {
            return new UpdateHandler(predictionsRef.where("hashtags", "array-contains", hashtagUpperCase)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId)
                    .endAt(oldestJotId)
                    .limit(jotCount)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.HASHTAG_JOTS, jots: jots, hashtag: hashtag});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId,
                oldestJotId)
        }

        if (newestJotId) {
            return new UpdateHandler(predictionsRef.where("hashtags", "array-contains", hashtagUpperCase)
                    .orderBy("createdTimestamp", "desc")
                    .startAt(newestJotId).limit(jotCount)
                    .onSnapshot(function (querySnapshot) {
                            _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                                if (jots.length) {
                                    dispatch({type: Actions.HASHTAG_JOTS, jots: jots, hashtag: hashtag});
                                }
                            })
                        },
                        function (error) {
                            console.log("fetch predictions: " + error)
                        }),
                newestJotId)
        }
        return new UpdateHandler(predictionsRef.where("hashtags", "array-contains", hashtagUpperCase)
            .orderBy("createdTimestamp", "desc")
            .limit(jotCount)
            .onSnapshot(function (querySnapshot) {
                    _TJDataBase.injectJotDataIntoAppState(dispatch, querySnapshot).then(jots => {
                        if (jots.length) {
                            dispatch({type: Actions.HASHTAG_JOTS, jots: jots, hashtag: hashtag});
                        }
                    })
                },
                function (error) {
                    console.log("fetch predictions: " + error)
                }))

    }

    static createJot(jotText: string, source: string, creatorName: string) {
        const userID = Auth.currentUserUID();
        console.assert(creatorName !== "")

        function addJot() {
            return db.collection(PREDICTIONS).add(
                {
                    creator: userID,
                    creatorName: creatorName,
                    text: jotText,
                    agree: 0,
                    disagree: 0,
                    createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    source: source
                });
        }

        function addJotReferenceToUserProfile(docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
            return db.collection(USERCOLLECTION).doc(userID).collection(CreatedPredictions).doc(docRef.id).set(
                {
                    CreatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
                }
            );
        }

        function CreateJotDataTracking(docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
            return function () {
                logEvent(analytics, 'select_content', {
                    content_type: 'Prediction Created',
                    content_id: docRef.id,
                    item_id: docRef.id,
                    creator: userID,
                    items: [{
                        item_id: docRef.id,
                        item_name: 'Prediction Created',
                        creator: userID
                    }]
                });
            };
        }

        async function addJotToSourceCollection(sourcePath: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, jotRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
            return sourcePath.collection(CreatedJots).doc(jotRef.id).set(
                {
                    jotId: jotRef.id,
                    jotCreator: userID,
                    createdTimestamp: firebase.firestore.FieldValue.serverTimestamp()
                }
            );
        }

        async function incrementSourceUsage(source: string) {
            const sourceCount = new Counter(db.collection(SOURCES).doc(source), "count");
            return sourceCount.incrementBy(1);
        }

        const sourcePath = db.collection(SOURCES).doc(source);

        return sourcePath.get().then(async sourceDoc => {
                try {
                    if (!sourceDoc.exists) {
                        await TJDataBase.addSource(source);
                    }

                    const jotDoc = await addJot();
                    await incrementSourceUsage(source);
                    await addJotReferenceToUserProfile(jotDoc);
                    await addJotToSourceCollection(sourcePath, jotDoc);
                    await CreateJotDataTracking(jotDoc);
                } catch (error) {
                    console.log("Error Creating a jot:");
                    console.log(error)
                }
            }
        );
    }


    static addSource(sourceString: string) {
        const userID = Auth.currentUserUID();
        console.assert(sourceString !== "")

        return db.collection(SOURCES).doc(sourceString).set(
            {
                name: sourceString,
                creator: userID,
                createdTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(sourceDoc => {
                return sourceDoc;
            })
            .catch(function (error) {
                console.log("Error adding a source:");
                console.log(error)
            })
    }

    static getSourceMatches(sourceString: string, dispatch: Dispatch<Action>) {
        if (sourceString === "") return

        const flexSearchInstance = new FlexSearch({
            encode: "extra",
            tokenize: "forward",
            threshold: 0,
        });

        let encodedString = flexSearchInstance.encode(sourceString);

        // Allow us to search for none latin characters
        const searchTerms = encodedString.length !== 0 ? encodedString : sourceString

        return db.collection(SOURCES).where("similarTerms", "array-contains", searchTerms).limit(5).get().then(function (matchesSnapshot) {
            let namesOfSources: ISuggestion[] = []
            matchesSnapshot.docs.forEach(doc => {
                let basicSource = {name: "", numberOfTimesSuggestionUsed: 0}
                const docData = doc.data();

                if(!!docData.name)
                {
                    basicSource.name = docData.name;
                }

                if(!!docData.count)
                {
                    basicSource.numberOfTimesSuggestionUsed = docData.count;
                }

                namesOfSources.push(basicSource)
            })
            dispatch({type: actionTypes.FOUND_SOURCE_MATCHES, sourceNames: namesOfSources})
        })
    }

    /////
    // TODO: consolidate this function with getSourceMatches, they are doing the same thing just need to pass a few more parameters
    static getHashtagMatches(hashtagString: string, dispatch: Dispatch<Action>) {
        if (hashtagString === "") return

        assert(hashtagString[0] !== "#");

        const flexSearchInstance = new FlexSearch({
            encode: "extra",
            tokenize: "forward",
            threshold: 0,
        });

        let encodedString = flexSearchInstance.encode(hashtagString);

        // Allow us to search for none latin characters
        const searchTerms = encodedString.length !== 0 ? encodedString : hashtagString

        return db.collection(HASHTAGS).where("similarTerms", "array-contains", searchTerms).limit(5).get().then(function (matchesSnapshot) {
            let namesOfHashtags: ISuggestion[] = []
            matchesSnapshot.docs.forEach(doc => {
                let basicSource = {name: "", numberOfTimesSuggestionUsed: 0}
                const docData = doc.data();

                if(!!docData.name)
                {
                    basicSource.name = docData.name;
                }

                if(!!docData.count)
                {
                    basicSource.numberOfTimesSuggestionUsed = docData.count;
                }

                namesOfHashtags.push(basicSource)
            })
            dispatch({type: actionTypes.FOUND_HASHTAG_MATCHES, hashtagNames: namesOfHashtags})
        })
    }
    //////

    static getSourceList(dispatch: Dispatch<Action>) {
        return db.collection(SOURCES).orderBy("createdTimestamp", "desc").limit(5).get().then(function (matchesSnapshot) {
            let namesOfSources: string[] = []
            matchesSnapshot.docs.forEach(doc => {
                namesOfSources.push(doc.data().name)
            })
        })
    }

    static saveBio(bio: string) {
        const userID = Auth.currentUserUID();

        return db.collection(USERCOLLECTION).doc(userID).update(
            {
                bio: bio,
            })
            .then(function () {
                logEvent(analytics, 'bio_changed', {
                    content_type: 'Bio changed',
                    content_id: userID,
                    item_id: userID,
                    user: userID,
                    items: [{item_id: userID, item_name: 'Bio changed', user: userID}]
                });
            })
            .catch(function (error) {
                console.log("Error updating bio:");
                console.log(error)
            })
    }

    static agreeJot(jotId: string) {
        const userID = Auth.currentUserUID();

        let batch = db.batch();


        batch.set(db.collection(PREDICTIONS).doc(jotId).collection(VOTERS).doc(userID),
            {
                user: userID,
                voted: AGREED,
                CreatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
        )

        // Track in user profile
        batch.set(db.collection(USERCOLLECTION).doc(userID).collection(PredictionsVotedOn).doc(jotId),
            {
                voted: AGREED,
                createdTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
        );

        return batch.commit().then(function () {
            let agree = new Counter(db.collection(PREDICTIONS).doc(jotId), "agree");
            return agree.incrementBy(1);
        })
            .then(function () {
                const userCreatedPrediction = db.collection(USERCOLLECTION).doc(userID).collection(CreatedPredictions).doc(jotId);
                userCreatedPrediction.get().then(function (doc) {
                    logEvent(analytics, 'select_content', {
                        content_type: 'Vote Cast',
                        item_id: jotId,
                        content_id: jotId,
                        /*voter*/ item_category: `${userID}`,
                        /*agreed*/ item_category2: `true`,
                        /*selfCreated*/ item_category3: `${doc.exists}`,
                        items: [{
                            item_id: jotId,
                            item_name: 'Vote Cast', /*voter*/
                            item_category: `${userID}`, /*agreed*/
                            item_category2: `true`, /*selfCreated*/
                            item_category3: `${doc.exists}`
                        }]
                    });
                })

            });
    }

    static disagreeJot(jotId: string) {
        const userID = Auth.currentUserUID();
        let batch = db.batch();

        batch.set(db.collection(PREDICTIONS).doc(jotId).collection(VOTERS).doc(userID),
            {
                user: userID,
                voted: DISAGREED,
                createdTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            });

        // Track in user profile
        batch.set(db.collection(USERCOLLECTION).doc(userID).collection(PredictionsVotedOn).doc(jotId),
            {
                voted: DISAGREED,
                createdTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
        )

        return batch.commit().then(function () {
            let disagree = new Counter(db.collection(PREDICTIONS).doc(jotId), "disagree");
            return disagree.incrementBy(1);
        })
            .then(function () {

                const userCreatedPrediction = db.collection(USERCOLLECTION).doc(userID).collection(CreatedPredictions).doc(jotId);
                userCreatedPrediction.get().then(function (doc) {
                    logEvent(analytics, 'select_content', {
                        content_type: 'Vote Cast',
                        content_id: jotId,
                        item_id: jotId,
                        /*voter*/ item_category: `${userID}`,
                        /*agreed*/ item_category2: `false`,
                        /*selfCreated*/ item_category3: `${doc.exists}`,
                        items: [{
                            item_id: jotId,
                            item_name: 'Vote Cast', /*voter*/
                            item_category: `${userID}`, /*agreed*/
                            item_category2: `false`, /*selfCreated*/
                            item_category3: `${doc.exists}`
                        }]
                    });
                });
            });
    }

    // Marking the answers correct should probably be moved into a server call
    static markJotTrue(jotId: string, resultKnownTimestamp: Date) {
        const userID = Auth.currentUserUID();

        let batch = db.batch();

        batch.set(db.collection(USERCOLLECTION).doc(userID).collection(MarkedPredictions).doc(jotId),
            {
                outcome: true,
                resultKnownTimestamp: firebase.firestore.Timestamp.fromDate(resultKnownTimestamp),
                closedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
        )

        batch.set(db.collection(PREDICTIONS).doc(jotId).collection(MARKED).doc(RESULT),
            {
                outcome: true,
                resultKnownTimestamp: firebase.firestore.Timestamp.fromDate(resultKnownTimestamp),
                closedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                markingUser: userID
            }
        )

        return batch.commit()
            .then(function () {
                logEvent(analytics, 'select_content', {
                    content_type: 'Prediction Marked',
                    content_id: jotId,
                    item_id: jotId,
                    /*voter*/ item_category: `${userID}`,
                    /*agreed*/ item_category2: `true`,
                    items: [{
                        item_id: jotId,
                        item_name: 'Prediction Marked', /*voter*/
                        item_category: `${userID}`, /*agreed*/
                        item_category2: `true`
                    }]
                });
            });
    }

    static markJotFalse(jotId: string, resultKnownTimestamp: Date) {
        const userID = Auth.currentUserUID();

        let batch = db.batch();

        batch.set(db.collection(USERCOLLECTION).doc(userID).collection(MarkedPredictions).doc(jotId),
            {
                outcome: false,
                resultKnownTimestamp: firebase.firestore.Timestamp.fromDate(resultKnownTimestamp),
                closedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
            }
        )

        batch.set(db.collection(PREDICTIONS).doc(jotId).collection(MARKED).doc(RESULT),
            {
                outcome: false,
                resultKnownTimestamp: firebase.firestore.Timestamp.fromDate(resultKnownTimestamp),
                closedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                markingUser: userID
            }
        )

        return batch.commit()
            .then(function () {
                logEvent(analytics, 'select_content', {
                    content_type: 'Prediction Marked',
                    content_id: jotId,
                    item_id: jotId,
                    /*voter*/ item_category: `${userID}`,
                    /*agreed*/ item_category2: `false`,
                    items: [{
                        item_id: jotId,
                        item_name: 'Prediction Marked', /*voter*/
                        item_category: `${userID}`, /*agreed*/
                        item_category2: `false`
                    }]
                });
            });
    }
}

export let TJDataBase = _TJDataBase;
