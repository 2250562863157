import React from 'react';

import './LoadingIndicator.css';

const LoadingIndicator = (props: any) => {
    return (
        // <span className={"LoadingIndicator-wrapper"}>
            <img className={"LoadingIndicator"} src={"img/LoadingIcon.png"} alt="LoadingIndicator"/>
        // </span>
    );
};


export default LoadingIndicator;