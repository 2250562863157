import './JotFeed.css';

import React, {useEffect, useState, useRef, createRef, RefObject, useCallback} from 'react';
import {IJot} from "../../Store/DataInterfaces";
import _ from 'lodash';
import firebase from 'firebase/compat/app';
type Timestamp = firebase.firestore.Timestamp;

interface IJotFeedProps {
    jots: IJot[],
    jotConstructor: (prediction: IJot) => JSX.Element
    moreJotsRequest: (jotCount: number) => void
    refreshViewedJots: (newTimestamp: Timestamp, oldTimestamp: Timestamp) => void
}

const jotCountDelta = 10;
const noTimestamp = "No timestamp";
const JotFeed = (props: IJotFeedProps) => {

    const loader = useRef<HTMLHeadingElement>(null);
    const jotsDomElements = useRef<RefObject<HTMLLIElement>[]>([]);

    if (jotsDomElements.current.length !== props.jots.length) {
        // add or remove refs
        jotsDomElements.current = Array(props.jots.length).fill(HTMLLIElement).map((_, i) => jotsDomElements.current[i] || createRef<HTMLLIElement>());
    }

    const [state, setState] = useState({testNumber: 0, firstTimeSetupComplete: false, newestVisibleJot: new firebase.firestore.Timestamp(0,0),
        oldestVisibleJot: firebase.firestore.Timestamp.now()});

    const stateRef = useRef(state);
    // This is done to allow callbacks to capture state by ref instead of value
    stateRef.current = state

    const jotsRef = useRef(props.jots)
    jotsRef.current = props.jots

    const requestFunction = _.debounce(() => {
            props.moreJotsRequest(jotCountDelta)},
        0)

    const updateLiveUpdatingJotPosition = useCallback(_.debounce((newTimestamp: Timestamp, oldTimestamp: Timestamp) => {
            props.refreshViewedJots(newTimestamp, oldTimestamp)
        }, 3000), [])

    // here we handle what happens when user scrolls to Load More div
    // in this case we just update page variable
    const handleBottomReachedObserver = (entities: IntersectionObserverEntry[]) => {
        if (entities.length === 0) return;
        const target = entities[0];
        if (target.isIntersecting) {
            requestFunction()
        }
    }

    // Load more jots bottom reached
    useEffect(() => {
        const options = {
            root: null,
            // rootMargin: "200px",
            threshold: 0.1
        };

        const observer = new IntersectionObserver(handleBottomReachedObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }

        return () => {observer.disconnect()}
    }, []);

    useEffect(() => {

        const getNewestJotEntry = (entries: IntersectionObserverEntry[]) =>
        {
           const newestJotElement = entries.reduce((previousItem, currentItem) => {
                const preTimestamp = previousItem.target.getAttribute(`data-timestamp`);
                const currentTimestamp = currentItem.target.getAttribute(`data-timestamp`);

                if (currentTimestamp == null || currentTimestamp === noTimestamp) return previousItem
                if (preTimestamp == null || preTimestamp === noTimestamp) return currentItem

                return (parseInt(preTimestamp) > parseInt(currentTimestamp)) ? previousItem : currentItem
            })

            const newestJotId = newestJotElement.target.getAttribute(`data-id`)
            return jotsRef.current.filter(item => item.id === newestJotId);
        }

        const getOldestJotEntry = (entries: IntersectionObserverEntry[]) =>
        {
            const oldestJotElement = entries.reduce((previousItem, currentItem) => {
                const preTimestamp = previousItem.target.getAttribute(`data-timestamp`);
                const currentTimestamp = currentItem.target.getAttribute(`data-timestamp`);

                if (currentTimestamp == null || currentTimestamp === noTimestamp) return previousItem
                if (preTimestamp == null || preTimestamp === noTimestamp) return currentItem

                return (parseInt(preTimestamp) < parseInt(currentTimestamp)) ? previousItem : currentItem
            })

            const oldestJotId = oldestJotElement.target.getAttribute(`data-id`)
            return jotsRef.current.filter(item => item.id === oldestJotId);
        }

        const updateJotsWhileScrollingDown = (entities:  IntersectionObserverEntry[]) => {
            let oldestElementTimeStamp = stateRef.current.oldestVisibleJot;
            let newestElementTimeStamp = stateRef.current.newestVisibleJot;

            const visibleElements = entities.filter((element) => element.isIntersecting)
            if (visibleElements.length > 0)
            {
                const elementJot = getOldestJotEntry(visibleElements)
                if (elementJot[0].createdTimestamp > oldestElementTimeStamp) return
                oldestElementTimeStamp = elementJot[0].createdTimestamp
            }

            const invisibleElements = entities.filter((element) => !element.isIntersecting)
            if (invisibleElements.length > 0) {
                const elementJot = getOldestJotEntry(invisibleElements);
                if (elementJot[0].createdTimestamp > newestElementTimeStamp) return
                newestElementTimeStamp = elementJot[0].createdTimestamp
            }

            if (newestElementTimeStamp <= oldestElementTimeStamp)
            {
                return;
            }

            setState(state => ({...state, newestVisibleJot: newestElementTimeStamp, oldestVisibleJot: oldestElementTimeStamp}))
            updateLiveUpdatingJotPosition(newestElementTimeStamp, oldestElementTimeStamp)
        }

        const updateJotsWhileScrollingUp = (entities:  IntersectionObserverEntry[]) => {
            let oldestElementTimeStamp = stateRef.current.oldestVisibleJot;
            let newestElementTimeStamp = stateRef.current.newestVisibleJot;

            const visibleElements = entities.filter((element) => element.isIntersecting && element.intersectionRatio > 0)
            if (visibleElements.length > 0 )
            {
                const elementJot = getNewestJotEntry(visibleElements);
                if (elementJot[0].createdTimestamp < newestElementTimeStamp) return
                newestElementTimeStamp = elementJot[0].createdTimestamp
            }

            const invisibleElements = entities.filter((element) => !element.isIntersecting)
            if (invisibleElements.length > 0)
            {
                const elementJot = getNewestJotEntry(invisibleElements);
                if (elementJot[0].createdTimestamp < oldestElementTimeStamp) return
                oldestElementTimeStamp = elementJot[0].createdTimestamp
            }

            if (newestElementTimeStamp <= oldestElementTimeStamp)
            {
                return;
            }

            setState(state => ({...state, newestVisibleJot: newestElementTimeStamp, oldestVisibleJot: oldestElementTimeStamp}))
            updateLiveUpdatingJotPosition(newestElementTimeStamp, oldestElementTimeStamp)
        }

        const handleUpdateContentObserver = (entities: IntersectionObserverEntry[]) => {
            if (!stateRef.current.firstTimeSetupComplete && jotsRef.current.length > 2)
            {
                const jots = jotsRef.current
                const oldestjot = jotsRef.current.length >= 20? jotsRef.current[19] : jots[jots.length - 1]
                setState(state => ({...state, testNumber: (state.testNumber + 1),  firstTimeSetupComplete: true, oldestVisibleJot: oldestjot.createdTimestamp, newestVisibleJot: jots[0].createdTimestamp}))
            }

            if (!stateRef.current.firstTimeSetupComplete){console.log("failed load"); return;}

            const visibleItems = entities.filter(enity => enity.isIntersecting)
            if (visibleItems.length === 0 ) return;

            let scrollingDown = false;

            if (visibleItems.length)
            {
                const timestamp = getOldestJotEntry(visibleItems)
                scrollingDown = timestamp[0].createdTimestamp < stateRef.current.oldestVisibleJot
            }
            else
            {
                const timestamp = getOldestJotEntry(entities)
                scrollingDown = timestamp[0].createdTimestamp > stateRef.current.newestVisibleJot
            }

            if (scrollingDown)
            {
                updateJotsWhileScrollingDown(entities)
            }
            else
            {
                updateJotsWhileScrollingUp(entities)
            }
        }

        const liveUpdateFunction = (entities:  IntersectionObserverEntry[]) => {handleUpdateContentObserver(entities)}

        const options = {
            threshold: 0.0
        };

        const observer = new IntersectionObserver(liveUpdateFunction, options);
        if (jotsDomElements.current) {
            for (const item of jotsDomElements.current)
            {
                const domElement = item.current
                if (!domElement) continue
                observer.observe(domElement);
            }
        }

        return () => {observer.disconnect()}
    }, [props.jots.length, updateLiveUpdatingJotPosition]);

    return <section className="PredictionFeed">
        <ul style={{listStyleType: "none"}}>
            {(props.jots) ?
                props.jots.map((jot: IJot, index) => {
                    return (
                    <li key={jot.id} data-id={jot.id} data-debugging-text={jot.text} data-timestamp={(jot.createdTimestamp)? jot.createdTimestamp.toMillis() : noTimestamp} ref={jotsDomElements.current[index]}>
                        {props.jotConstructor(jot)}
                    </li>
                    )
                })
                :
                (<h1>Load failed</h1>)}
        </ul>
        <h3 ref={loader}>Loading Jots...</h3>
    </section>;
}

export default JotFeed;
