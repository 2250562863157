import {AppState, FeedJotIds, IJot, JotLookup} from "./DataInterfaces";
import * as paths from "../PagePathConstants";
import {HashtagFeedId, MainFeedId, ProfileFeedId, SourceFeedId} from "./Reducer";

export function getJots(feedId: string, jotFeeds: FeedJotIds, jots: JotLookup) {
    let jotsForId: IJot[] = [];
    const feedJotIds = jotFeeds.get(feedId);

    if (feedJotIds) {
        feedJotIds.forEach((jotId) => {
            const jot = jots.get(jotId)
            if (jot) {
                jotsForId = [...jotsForId, jot]
            }
        })
    }

    return jotsForId
}

export function getFeedIdForPath(state: AppState) {
    const urlPathSubStrings = state.currentRootPath.split('/')
    if (urlPathSubStrings.length === 0) return "";

    const pathStem = "/" + urlPathSubStrings[1];

    if (pathStem === paths.MainPagePath) {
        return MainFeedId;
    } else if (pathStem === paths.SourceJotsPage) {
        return SourceFeedId + state.selectedSource;
    } else if (pathStem === paths.HashtagJotsPage) {
        return HashtagFeedId + state.selectedHashtag;
    } else if (pathStem === paths.ProfilePage) {
        return ProfileFeedId + state.pageOwner;
    }

    return ""
}
