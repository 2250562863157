import './SourceJots.css';

import React from 'react';
import {connect} from "react-redux";
import {AppState} from "../../Store/DataInterfaces";
import * as Actions from "../../Store/Actions";
import GenericCreationFeed from '../../GenericUI/GenericCreationFeed/GenericCreationFeed';

interface IProps {
    selectedSource: string
    dispatch: any;
}

const SourceJots = (props: IProps) => {
    return (
        <GenericCreationFeed pageName={"Source: " + props.selectedSource}
                             presetSource={props.selectedSource}
                             moreJots={(jotCount: number) => props.dispatch({
                                 type: Actions.SOURCE_FEED_FETCH_MORE_JOTS,
                                 sourceId: props.selectedSource,
                                 jotCount: jotCount
                             })}
                             refreshJots={(newTimestamp: Actions.Timestamp, oldTimestamp: Actions.Timestamp) => props.dispatch({
                                 type: Actions.SOURCE_FEED_LIVE_UPDATE_POSITION,
                                 sourceId: props.selectedSource,
                                 newestTimestamp: newTimestamp,
                                 oldestTimestamp: oldTimestamp
                             })}/>
    );
};

const mapStateToProps = (state: AppState) => {
    interface stateToProps {
        selectedSource: string
    }

    let mapObject: stateToProps = {
        selectedSource: state.selectedSource
    }

    return mapObject;
};

export default connect(mapStateToProps)(SourceJots);
