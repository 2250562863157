import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "./AutocompleteField.css"
import {ISuggestion} from "../../Store/DataInterfaces";

interface IProps {
    suggestions: ISuggestion[];
    className: string;
    enteredTextDidChange: (text: string) => void;
    itemSelected?: (text: string) => void;
}

const AutocompleteField = (props: IProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    let focusTimer = useRef<NodeJS.Timeout>();

    interface IAutocompleteFieldState {
        currentText: string;
        activeIndex: number;
        focused: boolean;
        userPickingAnOptions: boolean;
    }

    const [state, setState] = useState<IAutocompleteFieldState>({
        currentText: "",
        activeIndex: 0,
        focused: false,
        userPickingAnOptions: false
    });

    useEffect(() => {
        setState(state => ({...state, activeIndex: 0}));
    }, [props.suggestions.length])

    const textEntered = (event: ChangeEvent<HTMLInputElement>) => {
        setState({...state, currentText: event.currentTarget.value.trim()});
        props.enteredTextDidChange(event.currentTarget.value.trim());

        if (props.itemSelected) {
            props.itemSelected(event.currentTarget.value.trim());
        }
    }

    const userPickedValue = (value: string) => {
        setState({
            ...state,
            currentText: value,
            userPickingAnOptions: false
        });
        if (inputRef.current) {
            inputRef.current.value = value;
            props.enteredTextDidChange(value);
            inputRef.current.blur()
        }
        if (props.itemSelected)
        {
            props.itemSelected(value);
        }
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            case "ArrowDown":

                let index = (state.activeIndex !== props.suggestions.length - 1) ? state.activeIndex + 1 : state.activeIndex
                setState({
                    ...state,
                    activeIndex: (state.userPickingAnOptions) ? index : 0,
                    userPickingAnOptions: true
                });
                return false;
            case "ArrowUp":
                setState({
                    ...state,
                    activeIndex: (state.activeIndex !== 0) ? state.activeIndex - 1 : state.activeIndex,
                    userPickingAnOptions: (state.activeIndex === 0) ? false : true
                });
                return false;
            case "Escape":
                setState({
                    ...state,
                    activeIndex: 0,
                    userPickingAnOptions: false
                });
                return false;
            case "Enter":
                event.preventDefault();
                if (state.userPickingAnOptions === false && inputRef.current && inputRef.current.value.length > 0) {
                    if (props.itemSelected) {
                        props.itemSelected(state.currentText);
                        inputRef.current.blur()
                    }
                    break;
                }
                const suggestion = props.suggestions[state.activeIndex];
                if (inputRef.current) {
                    userPickedValue(suggestion.name);
                }

                return false;
            default:
                return true;
        }
    }

    const onFocus = (_: any) => {
        // Clear timeout that was going to blur on this tick of the main loop
        focusTimer.current && clearTimeout(focusTimer.current)
        setState(state => {
            return {...state, focused: true}
        });
    }

    const onBlur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
        // Force blur update to next tick of the main loop.
        focusTimer.current = setTimeout(() => {
            setState(state => {
                return {...state, focused: false}
            });
        }, 200)
    }


    const suggestionClicked = (event: React.MouseEvent<HTMLLIElement>) => {
        if (inputRef.current) {
            const suggestion = event.currentTarget.getAttribute(`id`);
            if (suggestion){
                userPickedValue(suggestion);
            }
        }
    }

    const SuggestionsListComponent = () => {
        return <React.Fragment>
            {(state.focused && !props.suggestions.length) ?
                <div className="no-suggestions">
                    {state.currentText.length > 0 && <p>No Results</p>}
                </div>
                :
                (state.focused && props.suggestions.length) > 0 &&
                <ul className="suggestions">
                    {props.suggestions.map((suggestion, index) => {
                        return (
                            <li className={`${(state.userPickingAnOptions && index === state.activeIndex) ? "selectedSuggestion" : "unselectedSuggestion"}`}
                                key={suggestion.name}
                                onClick={suggestionClicked}
                                id={suggestion.name}
                                data-index={index}
                                role={'presentation'}
                            >
                                {`${suggestion.name}`} <span className={"sourceCountDisplay"}>{`${suggestion.numberOfTimesSuggestionUsed ? `usage: ${suggestion.numberOfTimesSuggestionUsed}`: ""}`}</span>
                            </li>
                        );
                    })}
                </ul>}
        </React.Fragment>
    }

    return (
        <div className={`autocompleteContainer ${props.className}`} onFocus={onFocus} onBlur={onBlur}>
            <input
                className={"autocompleteInput"}
                ref={inputRef}
                type="text"
                onChange={textEntered}
                onKeyDown={onKeyDown}
                placeholder={"add source"}
                maxLength={30}
            />
            <SuggestionsListComponent/>
        </div>
    );
}

export default AutocompleteField;
