import React from 'react';
import {connect} from "react-redux";
import {AppState} from "../../Store/DataInterfaces";

import './WhatsNew.css';


const WhatsNew = (props: any) => {

    return (
        <div className="WhatsNew">
            <h1 className={"whatsNewTitle"}>What's New</h1>

            <div className={"whatsNewContainer"}>
                <ul>
                    <li>
                        <b>04/09/22:</b> <br/> #️⃣ Hashtags are here, so you can focus on the topics you care about!
                        Simply type # and the hashtag you want.
                        <br/>  You can select a hashtag in any jot and view all other jots with the same hashtag.
                        <br/>    <br/>
                        You can now select urls in a jot to take you to the link.
                        <br/>    <br/>
                        Jots now have the created date and time, so you can always tell if your time focused dates were correct.
                        <br/>    <br/>
                        We tweaked the input box and fixed several pesky bugs where profile page jots were not acting as expected
                        <br/>    <br/>
                        Happy Jotting! 👀
                    </li>
                    <li>
                        <b>07/01/22:</b> <br/> Make your own sources! ✍
                        <br/>️If they don't exist, well now you have the power
                        <br/><br/> Browse jots just for the sources you love, you and others can find your space
                        <br/>    <br/>
                        Happy Jotting! 🤝
                    </li>
                    <li>
                        <b>26/10/21:</b> <br/> ✅❎ Jots now have a time the result became known
                        <br/> This means you only get the the glory of being correct if you voted in time
                        <br/>  <br/> 🔨 Many background changes have happened and are coming 🤓<br/>  <br/>
                        Happy Jotting! ⏳
                    </li>
                    <li>
                        <b>2/05/21:</b> <br/> ⭐️ Jots now have sources
                        <br/>
                        ⚡️ You can now see who the most accurate jotter is on their profile
                        <br/> ☀️ oh and the score system got a rework
                        <br/> <br/>
                        Happy Jotting! 🌈
                    </li>
                    <li>
                        <b>21/03/21:</b> <br/> Endless scrolling is here! Now you can find any jot you have ever created, voted on, read, or missed 🎉
                    </li>
                    <li>
                        <b>27/11/20:</b> <br/> Any Jotter that has voted can mark a jot result
                    </li>
                    <li>
                        <b>23/10/20:</b> <br/> You can now get in touch with us using the new in app feedback
                    </li>
                    <li>
                        <b>16/10/20:</b> <br/> Every Jotter can now view any Jotter's profile
                    </li>
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);
