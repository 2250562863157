import React, {useEffect, useState} from 'react';
import {AppState, IUserIdentifier, IUserDetails} from '../Store/DataInterfaces'
import { connect } from 'react-redux';
import * as Actions from "../Store/Actions";
import * as ActionFactory from "../Store/ActionFactory";

import * as paths from "../PagePathConstants";

import './MenuBar.css';

const MenuBar = (props: any) => {
    const mediaQueryBreakSize = 1354;

    interface IMenuBarState {
        menuPopoverVisible: boolean;
        userImage: string | undefined;
        loadedWidthListener: boolean
    }

    const [state, setState] = useState<IMenuBarState>({menuPopoverVisible: false, userImage: undefined, loadedWidthListener: false});

    useEffect(() =>
        {
            setState({...state, menuPopoverVisible: false});
            if (typeof window !== `undefined` && !state.loadedWidthListener) {
                const x = window.matchMedia(`(max-width: ${mediaQueryBreakSize}px)`)

                if (x.addEventListener !== undefined)
                {
                    x.addEventListener('change', changeFeedbackButtonVisibilityOnPage) // Attach listener function on state changes
                }
                else
                {
                    // Use deprecated method call to support older safari browsers (seen on iOS 11)
                    x.addListener(changeFeedbackButtonVisibilityOnPage)
                }

                changeFeedbackButtonVisibilityOnPage();
                setState({...state, loadedWidthListener: true})
             }
        },
[props.currentRootPath]);

    const isCurrentPage = (pagePath: string) => {
        return props.currentRootPath === pagePath
    }

    const tryPushMainPage = () => {
        if (isCurrentPage(paths.MainPagePath)) return

        props.history.push(paths.MainPagePath)
    }

    const tryPushProfilePage = () => {
        if (isCurrentPage(paths.ProfilePage)) return

        props.history.push(paths.ProfilePage)
    }

    const tryPushOtherUserProfilePage = (userId: string) => {
        props.history.push(paths.ProfilePage + "/" + userId)
    }

    const tryPushWhatsNewPage = () => {
        if (isCurrentPage(paths.WhatsNewPage)) return

        props.history.push(paths.WhatsNewPage)
    }

    const showUserFeedbackModel = () => {
        // @ts-ignore
        if(typeof doorbell !== 'undefined') doorbell.show();
    }

    const changeFeedbackButtonVisibilityOnPage = () => {
        // @ts-ignore
        if(window.doorbellOptions) window.doorbellOptions.hideButton = (window.innerWidth < mediaQueryBreakSize)
        // @ts-ignore
        if(typeof doorbell !== 'undefined') doorbell.refresh();
    }

    return (
    <nav className={"MenuBar"}>
        {props.userLoggedInState ?
        <div className={"navigation-Section"}>
            <div className={"drop-down-navigation-buttons"}>
                <button className={"dropdown-nav"} aria-label={"dropdown navigation"} onClick={()=>{setState({...state, menuPopoverVisible: !state.menuPopoverVisible })}}><img src={state.menuPopoverVisible ? "/img/menu x.svg" : "/img/Hamburger.svg" } alt={"Hamburger menu"}/></button>
                {state.menuPopoverVisible && <div className={"popover-menu"}>
                    <button className={`page-button ${isCurrentPage(paths.MainPagePath)? "selected-page-button": ""} `} onClick={()=>{setState({...state, menuPopoverVisible: false}); tryPushMainPage()}}>All Jots</button>
                    <div className={"popover-line-divider"}/>
                    <button className={`page-button ${isCurrentPage(paths.ProfilePage)? "selected-page-button": ""}`} onClick={()=>{setState({...state, menuPopoverVisible: false}); tryPushProfilePage()}}>My Profile</button>
                    <button className={`page-button`} onClick={()=>{setState({...state, menuPopoverVisible: false}); tryPushProfilePage()}}>Your score: {props.currentUserScore}</button>
                    {props.otherUsers.length > 0 && <div className={"popover-line-divider"}/>}
                    {props.otherUsers.map( (userIdentifier: IUserIdentifier) =>
                            <button key={userIdentifier.userId} className={`page-button`} onClick={()=>{setState({...state, menuPopoverVisible: false}); tryPushOtherUserProfilePage(userIdentifier.userId)}}>{userIdentifier.userName}</button>
                    )}
                    <div className={"popover-line-divider"}/>
                    <button className={"page-button"} onClick={()=>{setState({...state, menuPopoverVisible: false}); showUserFeedbackModel()}}>Give Feedback</button>
                    <div className={"popover-line-divider"}/>
                    <button className={"page-button"} onClick={()=>{setState({...state, menuPopoverVisible: false}); tryPushWhatsNewPage()}}>What's New</button>
                    <div className={"popover-line-divider"}/>
                    <button className={"page-button"} onClick={()=>{setState({...state, menuPopoverVisible: false}); props.userLoggedOut()}}>Log Out</button>
                </div>}
            </div>
            <div className={"broken-out-navigation-buttons"}>
                <button id={"all-jots-page-button"} className={`page-button ${isCurrentPage(paths.MainPagePath)? "selected-page-button": ""} `} onClick={tryPushMainPage}>All Jots</button>
                <div className={"page-button-line-divider"} id={"page-button-line-divider-1"}/>
                <button id={"profile-page-button"} className={`page-button ${isCurrentPage(paths.ProfilePage)? "selected-page-button": ""}`} onClick={tryPushProfilePage}>My Profile</button>
                <div className={"page-button-line-divider"} id={"page-button-line-divider-2"}/>
                <button id={"whats-new-page-button"} className={`page-button ${isCurrentPage(paths.WhatsNewPage)? "selected-page-button": ""}`} onClick={tryPushWhatsNewPage}>What's New</button>
            </div>
        </div>: ""}
        {props.userLoggedInState && <h2 id={"userScore"}>Your score: {props.currentUserScore}</h2>}
        <ul className={"MenuBar-nav"}>
            {props.userLoggedInState ? <button className={"logout-button"} onClick={props.userLoggedOut}>Log Out</button> : ""}
        </ul>
        <h1 className={"tjlabsTitle"}>TJ Labs</h1>
    </nav>
    );
};

const mapStateToProps = (state: AppState) => {

    function getOtherUsers() {
        const allSeenUsers = Array.from(state.userIdentifiers.values()).filter(userIdentifier => userIdentifier.userId !== state.currentUserID);
        if (allSeenUsers.length < 3)
        {
            return allSeenUsers
        }
        else
        {
            return allSeenUsers.slice(0, 3);
        }
    }

    let stateProps = {
        userLoggedInState: state.userLoggedIn,
        currentRootPath: state.currentRootPath,
        otherUsers: getOtherUsers(),
        currentUserScore: 0,
        currentUserName: "",
        currentUserEmail: "",
    }

    const userDetails =  state.users.get(state.currentUserID)
    if (userDetails)
    {
        stateProps.currentUserScore = userDetails.score;
        stateProps.currentUserName = userDetails.userName;
        const email = userDetails.email;
        if (email)
        {
            stateProps.currentUserEmail = email;
        }
    }

    return stateProps;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        userLoggedIn: () => dispatch(ActionFactory.UserLogin()),
        userLoggedOut: () => dispatch({type: Actions.USER_LOGOUT}),
        fetchUserDetails: () => dispatch(ActionFactory.fetchUserDetails()),
        storeCurrentUserDetails: (userDetails: IUserDetails) => dispatch(ActionFactory.currentUserDetails(userDetails))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
