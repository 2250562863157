import {Action} from 'redux';
import {IJot, IUserDetails} from "./DataInterfaces";
import firebase from 'firebase/compat/app';
export type Timestamp = firebase.firestore.Timestamp;

export const PENDING_BACKEND_CURRENT_USER_VOTES = "Pending_Backend_current_user_voes";
export interface PendingBackendCurrentUserVotes extends Action<typeof PENDING_BACKEND_CURRENT_USER_VOTES> {
}

export const BACKEND_CURRENT_USER_VOTES = "Backend_current_user_voes";
export interface BackendCurrentUserVotes extends Action<typeof BACKEND_CURRENT_USER_VOTES> {
    votes: Map<string, boolean>
}

export const USER_LOGIN = "USER_LOGIN";
interface UserLogInAction extends Action<typeof USER_LOGIN> {
}

export const USER_LOGOUT = "USER_LOGOUT";
interface UserLogOutAction extends Action<typeof USER_LOGOUT> {
}

export const SAVING_BIO = "[uesrs profile] saving_bio";
export interface SavingBio extends Action<typeof SAVING_BIO> {
}

export const BIO_SAVED = "[users profile] bio_saved"
export interface BioSaved extends Action<typeof BIO_SAVED> {
    bio: string
}

export const SAVING_BIO_FAILED = "saving_bio_failed";
export interface SavingBioFailed extends Action<typeof SAVING_BIO_FAILED> {
}

export const ROUTE_LOCATION_DID_UPDATE = "Route_Location_Did_Update";
export interface RouteLocationDidUpdate extends Action<typeof ROUTE_LOCATION_DID_UPDATE> {
    location: any
}

export const FETCH_USER_DATA = "Feed_Fetch_User_Data";
interface FetchUserData extends Action<typeof FETCH_USER_DATA> {
    type: typeof FETCH_USER_DATA
}

export const SAVING_USER_PREDICTION = "saving_user_prediction";
export interface SavingUserJot extends Action<typeof SAVING_USER_PREDICTION> {
}

export const ADDING_PREDICTION_FAILED = "adding_prediction_failed";
export interface AddingJotFailed extends Action<typeof ADDING_PREDICTION_FAILED> {
}

export const FETCH_PREDICTIONS = "Feed_Fetch_Jots";
interface FetchJots extends Action<typeof FETCH_PREDICTIONS> {
}

export const MAIN_FEED_FETCH_MORE_JOTS = "Main_Feed_Fetch_More_Jots";
export interface MainFeedFetchMoreJots extends Action<typeof MAIN_FEED_FETCH_MORE_JOTS> {
    jotCount: number
}

export const MAIN_FEED_LIVE_UPDATE_POSITION = "Main_Feed_Live_Update_Position";
export interface MainFeedLiveUpdatePosition extends Action<typeof MAIN_FEED_LIVE_UPDATE_POSITION> {
    newestTimestamp: Timestamp
    oldestTimestamp: Timestamp
}

export const SOURCE_FEED_FETCH_MORE_JOTS = "Source_Feed_Fetch_More_Jots";
export interface SourceFeedFetchMoreJots extends Action<typeof SOURCE_FEED_FETCH_MORE_JOTS> {
    sourceId: string
    jotCount: number
}

export const SOURCE_FEED_LIVE_UPDATE_POSITION = "Source_Feed_Live_Update_Position";
export interface SourceFeedLiveUpdatePosition extends Action<typeof SOURCE_FEED_LIVE_UPDATE_POSITION> {
    sourceId: string
    newestTimestamp: Timestamp
    oldestTimestamp: Timestamp
}

export const HASHTAG_FEED_FETCH_MORE_JOTS = "Hashtag_Feed_Fetch_More_Jots";
export interface HashtagFeedFetchMoreJots extends Action<typeof HASHTAG_FEED_FETCH_MORE_JOTS> {
    hashtagId: string
    jotCount: number
}

export const HASHTAG_FEED_LIVE_UPDATE_POSITION = "Hashtag_Feed_Live_Update_Position";
export interface HashtagFeedLiveUpdatePosition extends Action<typeof HASHTAG_FEED_LIVE_UPDATE_POSITION> {
    hashtagId: string
    newestTimestamp: Timestamp
    oldestTimestamp: Timestamp
}

export const PROFILE_FEED_FETCH_MORE_JOTS = "Profile_feed_Fetch_More_Jots";
export interface ProfileFetchMoreJots extends Action<typeof PROFILE_FEED_FETCH_MORE_JOTS> {
    userId: string
    jotCount: number
}

export const PROFILE_FEED_LIVE_UPDATE_POSITION = "Profile_Feed_Live_Update_Position";
export interface ProfileFeedLiveUpdatePosition extends Action<typeof PROFILE_FEED_LIVE_UPDATE_POSITION> {
    userId: string
    newestTimestamp: Timestamp
    oldestTimestamp: Timestamp
}

export const CURRENT_USER_CREATED_JOTS = "[profile] Current User Created Jots";
export interface CurrentUserCreatedJots extends Action<typeof CURRENT_USER_CREATED_JOTS> {
    jots: IJot[]
    userId: string
}

export const SOURCE_JOTS = "[Source Jots] Jots for given source";
export interface SourceJots extends Action<typeof SOURCE_JOTS> {
    jots: IJot[]
    source: string
}

export const HASHTAG_JOTS = "[Hashtag Jots] Jots for given hashtag";
export interface HashtagJots extends Action<typeof HASHTAG_JOTS> {
    jots: IJot[]
    hashtag: string
}

export const MAIN_FEED_JOTS_RETURNED = "MAIN_FEED_JOTS_RETURNED";
export interface ReturnedJots extends Action<typeof MAIN_FEED_JOTS_RETURNED> {
    jots: IJot[]
}

export const USER_ADDED_PREDICTION = "[jots feed] USER_ADDED_PREDICTION"
export interface UserAddedJot extends Action<typeof USER_ADDED_PREDICTION> {
    prediction: string
}

export const USER_AGREED_WITH_PREDICTION = "[jots feed] USER_AGREED_WITH_PREDICTION"
export interface UserAgreedWithJot extends Action<typeof USER_AGREED_WITH_PREDICTION> {
    jotId: string
}

export const USER_DISAGREED_WITH_PREDICTION = "[jots feed] USER_DISAGREED_WITH_PREDICTION"
export interface UserDisagreedWithJot extends Action<typeof USER_DISAGREED_WITH_PREDICTION> {
    jotId: string
}

export const USER_MARKED_JOT_TRUE = "[jots feed] USER_MARKED_JOT_TRUE"
export interface UserMarkedJotTrue extends Action<typeof USER_MARKED_JOT_TRUE> {
    jotId: string
    resultKnownTimestamp: Date
}

export const USER_MARKED_JOT_FALSE = "[jots feed] USER_MARKED_JOT_FALSE"
export interface UserMarkedJotFalse extends Action<typeof USER_MARKED_JOT_FALSE> {
    jotId: string
    resultKnownTimestamp: Date
}

export const ADD_SOURCE = "ADD_SOURCE"
export interface AddSource extends Action<typeof ADD_SOURCE> {
    sourceName: string
}

export const GET_SOURCE_MATCHES = "GET_SOURCE_MATCHES"
export interface GetSourceMatches extends Action<typeof GET_SOURCE_MATCHES> {
    sourceName: string
}

export const GET_HASHTAG_MATCHES = "GET_HASHTAG_MATCHES"
export interface GetHashtagMatches extends Action<typeof GET_HASHTAG_MATCHES> {
    hashtagName: string
}

export const FOUND_SOURCE_MATCHES = "FOUND_SOURCE_MATCHES"
export interface FoundSourceMatches extends Action<typeof FOUND_SOURCE_MATCHES> {
    sourceNames: [string]
}

export const FOUND_HASHTAG_MATCHES = "FOUND_HASHTAG_MATCHES"
export interface FoundHashtagMatches extends Action<typeof FOUND_HASHTAG_MATCHES> {
    hashtagNames: [string]
}

export const FOUND_SOURCE_LIST = "FOUND_SOURCE_LIST"
export interface FoundSourceList extends Action<typeof FOUND_SOURCE_LIST> {
    sourceNames: string[]
}

export const USER_DETAILS = "User Details";
export interface UserDetails extends Action<typeof USER_DETAILS> {
    userDetails: IUserDetails;
}

export const Current_USER_DETAILS = "Current User Details";
export interface SaveCurrentUserSignUpDetails extends Action<typeof Current_USER_DETAILS> {
    userDetails: IUserDetails;
}

export const LOGGING_IN = "Logging In";
export interface LoggingIn extends Action<typeof LOGGING_IN> {
}

export const LOGGED_IN = "Logged In";
export interface LoggedIn extends Action<typeof LOGGED_IN> {
}

export const LOG_IN_FAILED = "Log In Failed";
export interface LogInFailed extends Action<typeof LOG_IN_FAILED> {
}

export type ActionTypes =
    BackendCurrentUserVotes |
    UserLogInAction |
    UserLogOutAction |
    SavingBio |
    BioSaved |
    RouteLocationDidUpdate |
    SavingBioFailed |
    FetchUserData |
    FetchJots |
    CurrentUserCreatedJots |
    SourceJots |
    HashtagJots |
    ReturnedJots |
    UserAddedJot |
    MainFeedFetchMoreJots |
    MainFeedLiveUpdatePosition |
    SourceFeedFetchMoreJots |
    SourceFeedLiveUpdatePosition |
    HashtagFeedFetchMoreJots |
    HashtagFeedLiveUpdatePosition |
    ProfileFetchMoreJots |
    ProfileFeedLiveUpdatePosition |
    UserAgreedWithJot |
    UserDisagreedWithJot |
    UserMarkedJotTrue |
    UserMarkedJotFalse |
    UserDetails |
    SaveCurrentUserSignUpDetails |
    SavingUserJot |
    AddingJotFailed |
    AddSource |
    GetSourceMatches |
    FoundSourceMatches |
    FoundSourceList |
    FoundHashtagMatches |
    GetHashtagMatches |
    LoggingIn |
    LogInFailed;
