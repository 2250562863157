import React, {useEffect} from 'react';
import { useState } from 'react';

// TODO: Move this to the middleware
import Auth from '../../Store/Backend/Auth/Auth'

import './Welcome.css';
import {AppState} from "../../Store/DataInterfaces";
import * as Actions from "../../Store/Actions";
import {connect} from "react-redux";
import LoadingIndicator from "../../GenericUI/LoadingIndicator/LoadingIndicator";
import * as paths from "../../PagePathConstants";


const Welcome = (props: any) => {
    const [state, setState] = useState({email: "", password: "", failureMessage: ""}); // '' is the initial state value

    useEffect(() =>
        {
            if (!props.userLoggedIn) return;

            if (props.userProfileSetup)
            {
                props.history.push(paths.MainPagePath);
            }
            else
            {
                props.history.push(paths.SignUpPage);
            }
        },
        [props.userLoggedIn, props.userProfileSetup]);

    let logIn = () => {
        props.loggingIn();
        Auth.logIn(state.email, state.password)?.catch(function(e) {
            setState({...state, failureMessage: "Please check email and password"})
            props.logInFailed();
        });
    };

    return (
        <div className={"welcome-wrapper"}>
            <div className="Welcome">
                <div className={"detailsLeft"}>
                    <h1>Welcome to TJ Labs!</h1>
                    <h2>As a chosen one, your email address and assigned password will get you to the goodness inside.</h2>
                    <form id = "login-fields" >
                        <input id = "email-field"
                               type = "email"
                               placeholder = "e-mail"
                               onInput = {e => setState({...state, email: e.currentTarget.value})}/>
                        <input id = "password-field"
                               type = "password"
                               placeholder = "password"
                               onInput = {e => setState({...state, password: e.currentTarget.value})}/>
                        {props.showLoggingIn ?
                            <div className={"logging-in-wrapper"}><LoadingIndicator/> <p className={"loading-text"}>Logging in</p></div>
                            :
                            <button type = "submit" className={"welcome-button"} onClick = {() => logIn()}>Log In</button>
                        }
                        <p>{state.failureMessage}</p>
                    </form>
                </div>
                <div className={"detailsRignt"}>
                    <img className={"welcome-decal"} src="img/welcome-decal.svg" alt="Welcome page decale"/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {

    let stateProps = {
        showLoggingIn: state.loggingIn,
        userLoggedIn: state.userLoggedIn,
        userProfileSetup: false,
    }

    const userDetails =  state.users.get(state.currentUserID)
    if (userDetails)
    {
        stateProps.userProfileSetup = userDetails.userName !== "";
    }

    return stateProps;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loggingIn: () => dispatch({type: Actions.LOGGING_IN}),
        logInFailed: () => dispatch({type: Actions.LOG_IN_FAILED})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
