import React, {useRef, useState} from 'react';
import * as paths from "../../PagePathConstants";
import {Link} from 'react-router-dom';

import './JotCard.css';
import {IJot} from "../../Store/DataInterfaces";
import JotTextMarkup from "./JotTextMarkup";
import {Timestamp} from "../../Store/Actions";

interface Props {
    prediction: IJot;
    image: string;
    usersPastVote: boolean | undefined
    agree: () => void;
    disagree: () => void;
    markTrue: (resultKnownTimestamp: Date) => void;
    markFalse: (resultKnownTimestamp: Date) => void;
    currentUserOwnsJot: boolean
}


const JotCard = (props: Props) => {

    const millisecondsInAYear = 31536000000;

    interface IJotCardState {
        showingVotingSection: boolean;
        showingMarkingSection: boolean;

        markingTime: number;
        timeChangingAmount: number;
    }

    const [state, setState] = useState<IJotCardState>({
        showingVotingSection: false,
        showingMarkingSection: false,
        markingTime: Date.now(),
        timeChangingAmount: 1
    });

    const timeChangingInterval = useRef<NodeJS.Timeout>()
    const doublingInterval = useRef<NodeJS.Timeout>()

    function JotVotingSection() {
        return <div className={"jotVotingSection"}>
            <button id={`showVotingButtons-${props.prediction}`}
                    className={"showVotingButtons " + (!state.showingVotingSection && !state.showingMarkingSection ? 'showElement' : 'hideElement')}
                    type={"button"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setState({...state, showingVotingSection: true})
                    }}>
                Vote
            </button>
            <div
                className={"VotingWrapper " + (state.showingVotingSection && !state.showingMarkingSection ? 'showElement' : 'hideElement')}>
                <p className={"actionDescription"}>Cast your vote</p>
                <div className={"votingFlexContainer"}>
                    <button className={"backButton"} type={"button"}
                            onClick={() => setState({...state, showingVotingSection: false})}>Back
                    </button>
                    <div className={"flexboxSpacer"}/>
                    <button className={"VotingButton"} type={"button"}
                            onClick={() => {
                                setState({...state, showingVotingSection: false})
                                props.agree()
                            }
                            }>
                        Agree
                    </button>
                    <div className={"flexboxSpacer"}/>
                    <button className={"VotingButton"} type={"button"}
                            onClick={() => {
                                setState({...state, showingVotingSection: false})
                                props.disagree()
                            }}>Disagree
                    </button>
                </div>
            </div>
        </div>
    }

    function JotCastedVotes() {
        return <p className={"castedVotes"}>Vote Cast! {props.prediction.agree} people
            agree, {props.prediction.disagree} people
            disagree</p>
    }

    function JotVoteOutcomes() {
        let correctVoters = 0;
        let wrongVoters = 0;

        if (props.prediction.outcome) {
            correctVoters = props.prediction.agree;
            wrongVoters = props.prediction.disagree;
        } else {
            correctVoters = props.prediction.disagree;
            wrongVoters = props.prediction.agree;
        }

        return <p className={"castedVotes"}>{correctVoters} voters were right, {wrongVoters} voters were wrong</p>
    }

    function onTimeDecreased(this: any) {

        function decrease() {
            setState(state => {
                const proposedTime = state.markingTime - state.timeChangingAmount;
                if (proposedTime <= props.prediction.createdTimestamp.toDate().getTime()) {
                    return {...state, markingTime: props.prediction.createdTimestamp.toDate().getTime()};
                }

                return {...state, markingTime: proposedTime};
            })
        }

        function intervalChange() {
            function getValidChange(proposedChangeAmount: number) {
                return (proposedChangeAmount * 2) < millisecondsInAYear ? (proposedChangeAmount * 2) : millisecondsInAYear;
            }

            setState(state => ({...state, timeChangingAmount: getValidChange(state.timeChangingAmount)}))
        }

        removeIntervals()
        decrease()
        timeChangingInterval.current = setInterval(decrease, 150);
        doublingInterval.current = setInterval(intervalChange, 1000);
    }

    function onTimeIncreased(this: any) {

        function increase() {
            setState(state => {
                const proposedTime = state.markingTime + state.timeChangingAmount;
                if (proposedTime > Date.now()) {
                    return {...state, markingTime: Date.now()};
                }

                return {...state, markingTime: proposedTime};
            })
        }

        function intervalChange() {
            function getValidChange(proposedChangeAmount: number) {
                return (proposedChangeAmount * 2) < millisecondsInAYear ? (proposedChangeAmount * 2) : millisecondsInAYear;
            }

            setState(state => ({...state, timeChangingAmount: getValidChange(state.timeChangingAmount)}))
        }

        removeIntervals()
        increase();
        timeChangingInterval.current = setInterval(increase, 150);
        doublingInterval.current = setInterval(intervalChange, 1000);
    }

    function removeIntervals() {
        if (doublingInterval.current) {
            clearInterval(doublingInterval.current)
            setState(state => ({...state, timeChangingAmount: 1000}))
        }

        if (timeChangingInterval.current) {
            clearInterval(timeChangingInterval.current)
        }
    }

    function MarkingJotSection() {
        return <div className={"markingJotSection"}>
            <button id={`showMarkingButtons-${props.prediction}`}
                    className={"showMarkingButtons " + (!state.showingVotingSection && !state.showingMarkingSection ? 'showElement' : 'hideElement')}
                    type={"button"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setState({...state, showingMarkingSection: true, markingTime: Date.now()})
                    }
                    }>
                &gt;&gt; Result &lt;&lt;
            </button>
            <div
                className={"MarkingWrapper " + (state.showingMarkingSection && !state.showingVotingSection ? 'showElement' : 'hideElement')}>
                <p className={"actionDescription"}>Mark your result</p>
                <div className={"timeSection"}>
                    <div className={"timeButtons"}>
                        <button className={"backButton"} type={"button"}
                                onMouseDown={onTimeDecreased} onMouseUp={removeIntervals}
                                onMouseLeave={removeIntervals}>older
                        </button>
                        <button className={"backButton"} type={"button"}
                                onMouseDown={onTimeIncreased} onMouseUp={removeIntervals}
                                onMouseLeave={removeIntervals}>newer
                        </button>
                    </div>
                    <label>{`${new Date(state.markingTime).toLocaleDateString()} ${new Date(state.markingTime).toLocaleTimeString()}`}</label>
                </div>
                <div className={"markingFlexContainer"}>
                    <button className={"backButton"} type={"button"}
                            onClick={() => setState({...state, showingMarkingSection: false})}>Back
                    </button>
                    <div className={"flexboxSpacer"}/>
                    <button className={"markingButton"} type={"button"}
                            onClick={() => {
                                setState({...state, showingMarkingSection: false})
                                props.markTrue(new Date(state.markingTime))
                            }
                            }>
                        &gt;&gt; True &lt;&lt;
                    </button>
                    <div className={"flexboxSpacer"}/>
                    <button className={"markingButton"} type={"button"}
                            onClick={() => {
                                setState({...state, showingMarkingSection: false})
                                props.markFalse(new Date(state.markingTime))
                            }
                            }>
                        &gt;&gt; False &lt;&lt;
                    </button>
                </div>
            </div>
        </div>
    }

    function JotUserInteractions() {
        return <div className={"JotCreatorInteractionsTopLevel"}>
            {props.usersPastVote === undefined && <JotVotingSection/>}
            {(!state.showingVotingSection && props.usersPastVote !== undefined) && <MarkingJotSection/>}
        </div>;
    }

    function JotCreatorInteractions() {
        return <div className={"JotCreatorInteractionsTopLevel"}>
            {props.usersPastVote === undefined && !state.showingMarkingSection && <JotVotingSection/>}
            {props.usersPastVote === undefined && !state.showingVotingSection && !state.showingMarkingSection &&
                <div className={"flexboxSpacer"}/>}
            {!state.showingVotingSection && <MarkingJotSection/>}
        </div>;
    }

    function getTimeInDisplayFormat(time: Timestamp | undefined) {
        return <>{time?.toDate().toLocaleDateString()} {time?.toDate().toLocaleTimeString()}</>;
    }

    return (
        <article className={"JotCard"}>
            <div className={"headerWrapper"}>
                <Link to={paths.ProfilePage + "/" + props.prediction.creator}>
                    <h2 className={"creatorName"}>{props.prediction.creatorName}:</h2>
                </Link>
                {(props.prediction.outcome !== undefined) &&
                    <div className={"resultWrappers"}>{(props.prediction.outcome) ?
                        <h2 className={"jotResult"}>&gt;&gt; TruJot &lt;&lt;</h2> :
                        <h2 className={"jotResult"}>FalseJot</h2>}{getTimeInDisplayFormat(props.prediction.resultKnownTimestamp)}</div>}
            </div>
            {JotTextMarkup.markup(props.prediction.text)}
            {props.prediction.source && <React.Fragment>
                <br/>
                <Link to={paths.SourceJotsPage + "/" + props.prediction.source}>
                    <div className="display-linebreak">Source: <b>{props.prediction.source}</b></div>
                </Link>
            </React.Fragment>}
            <div className={"createdDate"}>
                Created: {getTimeInDisplayFormat(props.prediction.createdTimestamp)}
            </div>
            <br/>
            {props.prediction.predictionClosed === undefined &&
                <div>
                    {props.usersPastVote !== undefined && <JotCastedVotes/>}

                    {(props.currentUserOwnsJot) ?
                        <JotCreatorInteractions/> : <JotUserInteractions/>}
                </div>
            }
            {props.prediction.outcome !== undefined &&
                <JotVoteOutcomes/>
            }
        </article>
    )
}

export default JotCard;
