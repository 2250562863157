import React from "react";
import {Link} from "react-router-dom";
import * as paths from "../../PagePathConstants";
import {textContainsHashTag, textContainsURL} from "../../UtilFunctions";

class JotTextMarkup {
    static markup(jotText: string) {
        const splitText = jotText.split(/(\s+)/);
        return (<div className="display-linebreak">
            {splitText.map((word, index) => {

                const key = word + index;
                // Match on hashtag for internal navigation
                if (textContainsHashTag(word)) {
                    const hashtagText = word.slice(1, word.length);

                    return (<Link to={paths.HashtagJotsPage + "/" + hashtagText} key={key}>
                        <span> {word} </span>
                    </Link>)
                }
                // Match on  URL for clicking out of the site
                else if (textContainsURL(word)) {
                    if (word.match(/^http[s]*:\/\/[\w]+/i))
                    {
                        // valid URL
                        return (<a href={word} key={key} target="_blank" rel="noopener noreferrer">{word}</a>)
                    }
                    else
                    {
                        // prepend the current http protocol, to make the URL an absolute path
                        return (<a href={"//" + word} key={key} target="_blank" rel="noopener noreferrer">{word}</a>)
                    }
                }
                else {
                    return <span key={key}> {word} </span>
                }
            })}
        </div>);
    }
}

export default JotTextMarkup;
