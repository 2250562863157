import {ThunkDispatch} from 'redux-thunk'
import * as Actions from "./Actions";
import {TJDataBase} from "./Backend/DataBase/TJDataBase";
import {AnyAction} from "redux";
import {IUserDetails} from "./DataInterfaces";

export function UserLogin() {
    return function(dispatch: any): void {
        dispatch(
        {
            type: Actions.USER_LOGIN
        })
    }
}

export function userDetails(userDetails: IUserDetails) {
    return {
        type: Actions.USER_DETAILS,
        userDetails: userDetails
    }
}

export function currentUserDetails(userDetails: IUserDetails) {
    return {
        type: Actions.Current_USER_DETAILS,
        userDetails: userDetails
    }
}

export function BackendCurrentUserVotes(votes: Map<string, boolean>): Actions.BackendCurrentUserVotes {
    return {
        type: Actions.BACKEND_CURRENT_USER_VOTES,
        votes: votes
    }
}

export function saveUserDetails(details: IUserDetails) {
    return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        return TJDataBase.uploadUserProfile(details).then(function () {
            dispatch(currentUserDetails(details));
        });
    }
}

export function fetchUserDetails() {
    return function (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        return TJDataBase.fetchCurrentUserData().then(function(userDetails: IUserDetails) {
            dispatch(currentUserDetails(userDetails));
        });
    }
}

function savingUserJot(): Actions.SavingUserJot {
    return {
        type: Actions.SAVING_USER_PREDICTION
    }
}

export function createJot(prediction: string, source: string) {

    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        dispatch(savingUserJot());

        const state = getState();
        return TJDataBase.createJot(prediction, source, state.users.get(state.currentUserID).userName).then(function(votes: any) {
            // prediction added
            dispatch({type: Actions.USER_ADDED_PREDICTION, prediction: prediction});
        })
        .catch((reason => dispatch({type: Actions.ADDING_PREDICTION_FAILED})));
    }
}

function savingBio(): Actions.SavingBio {
    return {
        type: Actions.SAVING_BIO
    }
}

export function saveBio(bio: string) {

    return function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch(savingBio());

        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.

        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        return TJDataBase.saveBio(bio).then(function(votes: any) {
            // bio saved
            dispatch({type: Actions.BIO_SAVED, bio: bio});
        })
        .catch((reason => dispatch({type: Actions.ADDING_PREDICTION_FAILED})));
    }
}

