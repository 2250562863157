import React, {ChangeEvent, useRef, useState} from 'react';

import './PredictionCreationSlot.css';
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import {ISuggestion} from "../../Store/DataInterfaces";
import {textContainsHashTag} from "../../UtilFunctions";
import assert from "assert";

interface CreateJot {
    (jotText: string, source: string): void;
}

interface Props {
    pageName: string;

    createJot: CreateJot;
    savingJot: boolean;
    sources: ISuggestion[];
    hashtags: ISuggestion[];
    presetSource?: string

    getSourceMatches: (sourceName: string) => void
    getHashtagMatches: (sourceName: string) => void
    addSource: (sourceName: string) => void;
}

const PredictionCreationSlot = (props: Props) => {
    const maxJotLength = 512;
    const validModifierKeys = ['Backspace',
        'Shift',
        'Control',
        'Alt',
        'Delete',
        'Meta',
        'ArrowLeft',
        'ArrowUp',
        'ArrowRight',
        'ArrowDown']

    interface IState
    {
        prediction: string,
        source: string,
        displayInvalidMessage: boolean,
        textEditFocus: boolean,
        insertingHashTag: boolean,
        startCursorPosition: number,
        startNodePosition: number,
        endCursorPosition: number,
        endNodePosition: number
    }

    const [state, setState] = useState<IState>({
        prediction: "",
        source: props.presetSource ?? "",
        displayInvalidMessage: false,
        textEditFocus: false,
        insertingHashTag: false,
        startCursorPosition: 0,
        startNodePosition: 0,
        endCursorPosition: 0,
        endNodePosition: 0,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const sourceChanged =  (sourceName: string) =>
    {
        if (sourceName !== "" && sourceName !== "Select Source")
        {
            setState({...state, source: sourceName})
        }

        else
        {
            setState({...state, source: ""})
        }
    }

    const hashtagChanged = (hashtagName: string) =>
    {
        if (wrapperRef.current)
        {
            const currentText = wrapperRef.current.innerText;
            wrapperRef.current.innerText = currentText + hashtagName;
        }
    }

    const createJot = () =>
    {
        if (state.source !== "")
        {
            props.createJot(state.prediction, state.source)
            setState({...state, prediction: "", displayInvalidMessage:false})
        }
        else
        {
            setState({...state, displayInvalidMessage:true})
        }
    }

    const getRelevantSourcePreviews = (event:ChangeEvent<HTMLTextAreaElement>) => {
        setState({...state, source: event.currentTarget.value.trim()});
        props.getSourceMatches(event.currentTarget.value.trim());
    }

    const onKeyDown = (event : React.KeyboardEvent<HTMLDivElement>) => {
        // Maximum character limit reached
        if (!validModifierKeys.includes(event.key)) {
            // visible text character
            if (event.currentTarget.innerText.trim().length >= maxJotLength)
            {
                event.preventDefault();
            }
        }
    }

    const onKeyUp = (event : React.KeyboardEvent<HTMLDivElement>) => {
        // Detect user inserting hashtag
        // TODO: expand this to a user inserting a hashtag anywhere in the text
        const words = event.currentTarget.innerText.split(/(\s+)/);
        const lastWord = words[words.length - 1];
        const lastWordHashtag = textContainsHashTag(lastWord);

        if (lastWordHashtag) {
            const hashtagText = lastWord.slice(1, lastWord.length);
            props.getHashtagMatches(hashtagText)
        }

        if (lastWordHashtag)
        {
            setState({...state, insertingHashTag: lastWordHashtag});
        }
    }

    const onFocus = (_ : React.FocusEvent<HTMLDivElement>) => {
        setState({...state, textEditFocus: true});

        let selection = document.getSelection()
        if(wrapperRef.current && selection)
        {
            const element = document.getElementById('jotInput');
            if (element instanceof HTMLDivElement && element.childNodes[0])
            {
                const startNode = element.childNodes.length > state.startNodePosition? element.childNodes[state.startNodePosition] : element.childNodes[0]
                const endNode = element.childNodes.length > state.endNodePosition? element.childNodes[state.endNodePosition] : element.childNodes[element.childNodes.length -1]

                let newRange = document.createRange();
                newRange.setStart(startNode, Math.min(state.startCursorPosition, startNode?.textContent?.length ?? 0));
                newRange.setEnd(endNode, Math.min(state.endCursorPosition, endNode?.textContent?.length ?? 0))

                selection.removeAllRanges()
                selection.addRange(newRange);
            }
        }
    }

    // Happens before we lose the focus info
    const onBlurCapture = (event : React.FocusEvent<HTMLDivElement>) => {
        const selection = window.getSelection()
        if (selection && selection.anchorNode)
        {
            const element = document.getElementById('jotInput');
            assert(element instanceof HTMLDivElement)

            const range = selection.getRangeAt(0)
            let startNodePosition = 0
            let endNodePosition = 0
            element.childNodes.forEach((node, key) => {
                if (range.startContainer === node)
                {
                    startNodePosition = key
                }

                if (range.endContainer === node)
                {
                    endNodePosition = key
                }
            })

            setState({...state, startCursorPosition: range.startOffset,
                startNodePosition: startNodePosition,
                endCursorPosition: range.endOffset,
                endNodePosition: endNodePosition});
        }
    }

    // Happens after we lose the focus info
    const onBlur = (event : React.FocusEvent<HTMLDivElement>) => {
        let text = event.target.innerText;
        text = text.replace(/\n\n+/gm, '\n\n')

        setState({...state, prediction: text ?? "", textEditFocus: false});
        if (wrapperRef.current)
        {
            // We set this explicitly because the state of new lines may not always be different
            const currentText = wrapperRef.current.innerText;
            wrapperRef.current.innerText = text;
        }
    }

    const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        if (event.clipboardData) {
            event.preventDefault();
            const text = event.clipboardData.getData("text/plain");

            let selection = document.getSelection();
            if (selection) {
                const range = selection.getRangeAt(0);
                const currentTextSelection = range.toString()
                if ((text.length + event.currentTarget.innerText.length - currentTextSelection.length) < maxJotLength) {
                    let selectionRange = selection.getRangeAt(0);
                    selectionRange.deleteContents();
                    selectionRange.insertNode(document.createTextNode(text));
                    selectionRange.collapse(false);

                    // Move selection to end of selection
                    let newRange = selectionRange.cloneRange()
                    selection.removeAllRanges()
                    selection.addRange(newRange);
                    }
                }
            else
            {
                if (text.length + event.currentTarget.innerText.length < maxJotLength) {
                    document.execCommand("insertText", false, text);
                }
            }
        }
    }

    return (
        <div className={"PredictionCreationSlot"}>
            <h2>{props.pageName}</h2>
            <form name="uploadForm">
                <div ref={wrapperRef} id={"jotInput"} role={"textbox"} contentEditable={true}
                     suppressContentEditableWarning={true} onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyDown}
                     onKeyUp={onKeyUp} onPaste={onPaste} onBlurCapture={onBlurCapture}>
                    {(state.prediction || state.textEditFocus) ?
                        (state.prediction)
                        :
                        "What's your prediction"
                    }
                </div>
                {props.presetSource ?
                    <p>Source: {props.presetSource}</p>
                    :
                    <AutocompleteField className={"SuggestionDropdown"} suggestions={props.sources} enteredTextDidChange={props.getSourceMatches} itemSelected={sourceChanged}/>}

                {(state.displayInvalidMessage) && <p>Please select a source</p>}
                {props.savingJot ?
                    <div className={"create-prediction-wrapper"}><LoadingIndicator/> <p className={"loading-text"}>Publishing</p></div>
                    :
                    <button type="button" id="save" onClick={() => createJot()}>Create Prediction</button>
                }
            </form>
        </div>
    );
};

export default PredictionCreationSlot;
