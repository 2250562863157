import React from 'react';
import {connect} from "react-redux";
import {AppState} from "../../Store/DataInterfaces";

import './Settings.css';

const Settings = (props: any) => {

    return (
        <div className="Settings">
            <h1>Settings</h1>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        // userLogInState: state.userLoggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        // userLoggedIn: () => dispatch({type: Actions.USER_LOGIN}),
        // userLoggedOut: () => dispatch({type: Actions.USER_LOGOUT})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
