export const MainPagePath = "/your-jots";
export const RootPage = "/";
export const WelcomePage = "/welcome";
export const SignUpPage = "/user-sign-up-details";
export const ProfilePage = "/profile";
export const SettingsPage = "/settings";
export const SupportPage = "/support";
export const SourceJotsPage = "/source-jots";
export const HashtagJotsPage = "/hashtag-jots";
export const WhatsNewPage = "/whats-new";
