import firebase from 'firebase/compat/app';
import * as Actions from "./Actions";
type Timestamp = firebase.firestore.Timestamp;

export type FeedId = string
export type JotId = string
export type UserId = string

export type JotLookup =  Map<JotId, IJot>
export type FeedJotIds = Map<FeedId, Map<JotId, JotId>>

export interface IUserIdentifier {
    userName: string;
    userId: UserId;
}

export interface IUserDetails {
    userName: string;
    userId: UserId;
    email?: string;
    score: number;
    bio?: string;
    correctVoteCount?: number
    wrongVoteCount?: number
}

export interface IJot {
    readonly creator: UserId;
    readonly creatorName: string;
    readonly text: string;
    readonly agree: number;
    readonly disagree: number;
    readonly createdTimestamp: Timestamp;
    readonly source?: string;
    outcome?: boolean;
    predictionClosed?: boolean;
    resultKnownTimestamp?: Timestamp;
    closedTimestamp?: Timestamp;
    readonly markingUser?: string;
    readonly id: string;
}

export interface ISuggestion {
    readonly name: string;
    readonly numberOfTimesSuggestionUsed: number;
}

export interface AppState {
    userLoggedIn: boolean;
    pastVotes: any; // Map<UserId, boolean> this is a workaround for strange bug when constructing the reducer
    currentUserID: UserId;
    currentRootPath: string;
    pageOwner: UserId;
    selectedSource: string;
    selectedHashtag: string;
    users: Map<UserId, IUserDetails>;
    userIdentifiers: UserIdSet<IUserIdentifier>;
    jots: JotLookup;
    feedJotIds: FeedJotIds;
    savingJot: boolean;
    savingBio: boolean;
    loggingIn: boolean;
    sourceMatches: Array<ISuggestion>; // This is the test for search
    hashtagMatches: Array<ISuggestion>;
}

export class UserIdSet<T extends IUserIdentifier> extends Set<T> {
    add(value: T): this {
        let found = false;
        this.forEach(item => {
            if (value.userId === item.userId) {
                found = true;
            }
        });

        if (!found) {
            super.add(value);
        }

        return this;
    }
}

export class UpdateHandler
{
    readonly newestTimeBound?: Actions.Timestamp;
    readonly oldestTimeBound?: Actions.Timestamp;
    private handleToUpdater: () => void;

    constructor(handleToUpdater: () => void, newestTimeBound?: Actions.Timestamp, oldestTimeBound?: Actions.Timestamp)
    {
        this.handleToUpdater = handleToUpdater;
        this.newestTimeBound = newestTimeBound;
        this.oldestTimeBound = oldestTimeBound;
    }

    isTimeRangeContained(newestTime: Actions.Timestamp, oldestTime: Actions.Timestamp)
    {
        if(this.newestTimeBound && this.oldestTimeBound)
        {
            return (newestTime <= this.newestTimeBound) && (oldestTime >= this.oldestTimeBound)
        }
        return false
    }

    cleanup()
    {
        this.handleToUpdater();
    }
}
