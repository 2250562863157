import './GenericCreationFeed.css';

import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {AppState, ISuggestion, IJot, JotId} from "../../Store/DataInterfaces";
import * as Actions from "../../Store/Actions";
import JotFeed from "../../GenericUI/Jot/JotFeed";
import JotCard from "../../GenericUI/Jot/JotCard";
import PredictionCreationSlot from "./PredictionCreationSlot";
import * as ActionFactory from "../../Store/ActionFactory";
import {ADD_SOURCE, GET_SOURCE_MATCHES, GET_HASHTAG_MATCHES} from "../../Store/Actions";
import {getFeedIdForPath, getJots} from "../../Store/StoreUtil";

interface IProps {
    // Public props
    pageName: string;
    moreJots: (jotCount: number) => any;
    refreshJots: (newTimestamp: Actions.Timestamp, oldTimestamp: Actions.Timestamp) => void;
    presetSource?: string;

    // private props
    userPastVotes: Map<JotId, boolean>;
    savingJot: boolean;
    userId: string;
    sources: ISuggestion[];
    hashtags: ISuggestion[];
    createJot: (prediction: string, source: string) => void;
    agreeWithJot: (prediction: string) => void;
    disagreeWithJot: (prediction: string) => void;
    markJotTrue: (prediction: string, resultKnownTime: Date) => void;
    markJotFalse: (prediction: string, resultKnownTime: Date) => void;
    getSourceMatches: (sourceName: string) => void;
    getHashtagMatches: (sourceName: string) => void
    addSource: (sourceName: string) => void;
    jots: IJot[]
}

const minimumSizeOfJot = 10;

const GenericCreationFeed = (props: IProps) => {

    const createJot = (prediction: string, source: string) => {
        // TODO: ensure the jot has no more than 30 hashtags if it does we should block the jots creation. We need to ensure the backend rules protects against this

        if (prediction.length > minimumSizeOfJot) {
            props.createJot(prediction, source);
            window.scrollTo(0, 0);
        }
    }

    // on first load
    useEffect(() => {
        if (props.jots.length === 0) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <div className={"PredictionScrollingWrapper"}>
            <div className={"CreatePredictionPanel"}>
                <PredictionCreationSlot createJot={createJot} savingJot={props.savingJot} sources={props.sources} hashtags={props.hashtags}
                                        getSourceMatches={props.getSourceMatches} getHashtagMatches={props.getHashtagMatches} addSource={props.addSource} pageName={props.pageName} presetSource={props.presetSource}/>
            </div>
            <div className={"prediction-centre-screen-divider"}/>
            <JotFeed moreJotsRequest={props.moreJots}
                     refreshViewedJots={props.refreshJots}
                     jots={props.jots}
                     jotConstructor={(jot: IJot) =>
                         <JotCard
                             prediction={jot}
                             currentUserOwnsJot={jot.creator === props.userId}
                             usersPastVote={props.userPastVotes.get(jot.id)}
                             image={"temp path"}
                             agree={() => {
                                 props.agreeWithJot(jot.id);
                             }}
                             disagree={() => {
                                 props.disagreeWithJot(jot.id);
                             }}
                             markTrue={(date: Date) => {
                                 props.markJotTrue(jot.id, date);
                             }}
                             markFalse={(date: Date) => {
                                 props.markJotFalse(jot.id, date);
                             }}
                         />
                     }/>
            <div className={"bottom-spacer"}></div>
        </div>
    );
}

const mapStateToProps = (state: AppState) => {
    return {
        userPastVotes: (state.pastVotes instanceof Map) ? state.pastVotes as Map<JotId, boolean> : new Map<JotId, boolean>(),
        savingJot: state.savingJot,
        userId: state.currentUserID,
        sources: state.sourceMatches,
        hashtags: state.hashtagMatches,
        jots: getJots(getFeedIdForPath(state), state.feedJotIds, state.jots)
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createJot: (prediction: string, source: string) => dispatch(ActionFactory.createJot(prediction, source)),
        agreeWithJot: (jotId: string) => dispatch({type: Actions.USER_AGREED_WITH_PREDICTION, jotId: jotId}),
        disagreeWithJot: (jotId: string) => dispatch({type: Actions.USER_DISAGREED_WITH_PREDICTION, jotId: jotId}),
        markJotTrue: (jotId: string, resultKnownTimestamp: Date) => dispatch({
            type: Actions.USER_MARKED_JOT_TRUE,
            jotId: jotId,
            resultKnownTimestamp: resultKnownTimestamp
        }),
        markJotFalse: (jotId: string, resultKnownTimestamp: Date) => dispatch({
            type: Actions.USER_MARKED_JOT_FALSE,
            jotId: jotId,
            resultKnownTimestamp: resultKnownTimestamp
        }),
        getSourceMatches: (sourceName: string) => dispatch({type: GET_SOURCE_MATCHES, sourceName: sourceName}),
        getHashtagMatches: (hashtagName: string) => dispatch({type: GET_HASHTAG_MATCHES, hashtagName: hashtagName}),
        addSource: (sourceName: string) => dispatch({type: ADD_SOURCE, sourceName: sourceName})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericCreationFeed);
