import './Profile.css';

import React, {ChangeEvent, useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {AppState, IJot, IUserIdentifier, UserId} from "../../Store/DataInterfaces";
import * as Actions from "../../Store/Actions";
import * as ActionFactory from "../../Store/ActionFactory";
import JotFeed from "../../GenericUI/Jot/JotFeed";
import JotCard from "../../GenericUI/Jot/JotCard";
import {Link} from "react-router-dom";
import * as paths from "../../PagePathConstants";
import {getFeedIdForPath, getJots} from "../../Store/StoreUtil";

const Profile = (props: any) => {

    interface IProfileState {
        editedBio: string
        bioEdited: boolean
        loadedProfiles: Set<UserId>
    }

    const [state, setState] = useState<IProfileState>({
        editedBio: props.bio,
        bioEdited: false,
        loadedProfiles: new Set<UserId>()
    });

    const userIdRef = useRef(props.userId);

    useEffect(() => setState({...state, editedBio: props.bio, bioEdited: false,}), [props.bio]);
    useEffect(() => {
        userIdRef.current = props.userId
    }, [props.userId]);

    const bioTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const input: string = event.currentTarget.value ? event.currentTarget.value : "";

        // Intentionally set value either way. we should always try to upload what the user sees
        setState({...state, editedBio: input, bioEdited: (props.bio !== input)})
    }

    const saveBio = () => {
        if (state.editedBio === props.bio) return;

        props.saveBio(state.editedBio)

        setState({...state, bioEdited: false})
    }

    function ScrollToTopOnMount() {
        useEffect(() => {
            if (!state.loadedProfiles.has(props.userId)) {
                setState({...state, loadedProfiles: state.loadedProfiles.add(props.userId)})
                window.scrollTo(0, 0);
            }
        }, []);

        return null;
    }

    function fetchMoreJots(jotCount: number) {
        props.moreJots(userIdRef.current, jotCount)
    }

    function liveUpdateJots(newestTimestamp: Actions.Timestamp, oldestTimestamp: Actions.Timestamp) {
        props.refreshJots(userIdRef.current, newestTimestamp, oldestTimestamp)
    }

    return (
        <div className={"PredictionScrollingWrapper"}>
            <ScrollToTopOnMount/>
            <div className={"User-details"}>
                <div className={"user-top-level-details"}>
                    <h1 className={"profile-user-name"}>Name: {props.userName}</h1>
                    <h1 className={"profile-user-score"}>Score: <b>{props.score}</b></h1>
                    {(props.accuracy !== undefined) ?
                        <h1 className={"profile-accuracy"}> Accuracy: <b>{props.accuracy}</b></h1> :
                        <h1 className={"profile-accuracy"}></h1>}
                </div>
                {props.isProfileOwner ?
                    <form className={"bioForm"} name="bioForm">
                        <textarea value={state.editedBio} placeholder="Tell other jotters about you…"
                                  onChange={bioTextChanged} maxLength={512}/>
                        {state.bioEdited &&
                            <button type="button" id="save" onClick={saveBio}>Save</button>
                        }
                    </form>
                    :
                    <p>{(props.bio && props.bio.length > 0) ? props.bio : "This jotter has not shared anything about themselves"}</p>
                }
                <div className={"other-user-container"}>
                    <h1>Other Profiles:</h1>
                    <ul className={"other-user-collection"}>
                        {props.otherUsers.map((userIdentifier: IUserIdentifier) =>
                            <li key={userIdentifier.userId}>
                                <Link to={paths.ProfilePage + "/" + userIdentifier.userId}>
                                    <h2 className={"other-user-profile-button"}>{userIdentifier.userName}</h2>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className={"prediction-centre-screen-divider"}/>
            <JotFeed moreJotsRequest={fetchMoreJots} refreshViewedJots={liveUpdateJots} jots={props.jots}
                     jotConstructor={(prediction: IJot) =>
                         <JotCard prediction={prediction}
                                  currentUserOwnsJot={props.isProfileOwner}
                                  image={''}
                                  usersPastVote={props.userPastVotes.get(prediction.id)}
                                  agree={() => {
                                      props.agreeWithJot(prediction.id);
                                  }}
                                  disagree={() => {
                                      props.disagreeWithJot(prediction.id);
                                  }}
                                  markTrue={(date: Date) => {
                                      props.markJotTrue(prediction.id, date);
                                  }}
                                  markFalse={(date: Date) => {
                                      props.markJotFalse(prediction.id, date);
                                  }}
                         />
                     }/>
            <div className={"bottom-spacer"}/>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {

    interface stateToProps {
        jots: IJot[],
        userPastVotes: any,
        userName: string,
        userId: string,
        score: number,
        bio: string,
        isProfileOwner: boolean,
        otherUsers: IUserIdentifier[],
        correctVoteCount?: number,
        wrongVoteCount?: number,
        accuracy?: string
    }

    function getOtherUsers() {
        return Array.from(state.userIdentifiers.values()).filter(userIdentifier => userIdentifier.userId !== state.currentUserID);
    }

    let mapObject: stateToProps = {
        jots: getJots(getFeedIdForPath(state), state.feedJotIds, state.jots),
        userPastVotes: state.pastVotes,
        userName: "",
        userId: "",
        score: 0,
        bio: "",
        isProfileOwner: state.pageOwner === state.currentUserID,
        otherUsers: getOtherUsers(),
    }

    const userDetails = state.users.get(state.pageOwner)
    if (userDetails) {
        mapObject.userName = userDetails.userName;
        mapObject.userId = userDetails.userId;
        mapObject.score = userDetails.score;
        mapObject.bio = userDetails.bio ?? "";
        mapObject.isProfileOwner = (state.pageOwner === state.currentUserID);
        if (userDetails.correctVoteCount) {
            mapObject.correctVoteCount = userDetails.correctVoteCount;
        }

        if (userDetails.wrongVoteCount) {
            mapObject.wrongVoteCount = userDetails.wrongVoteCount;
        }
    }

    if (mapObject.correctVoteCount && mapObject.wrongVoteCount) {
        const untruncatedAccuracy = mapObject.correctVoteCount / (mapObject.correctVoteCount + mapObject.wrongVoteCount);
        mapObject.accuracy = (untruncatedAccuracy * 100).toFixed(2) + "%";
    } else if (mapObject.correctVoteCount) {
        mapObject.accuracy = "100%";
    } else if (mapObject.wrongVoteCount) {
        mapObject.accuracy = "0%";
    }

    return mapObject;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        saveBio: (prediction: string) => dispatch(ActionFactory.saveBio(prediction)),
        agreeWithJot: (prediction: string) => dispatch({
            type: Actions.USER_AGREED_WITH_PREDICTION,
            jotId: prediction
        }),
        disagreeWithJot: (prediction: string) => dispatch({
            type: Actions.USER_DISAGREED_WITH_PREDICTION,
            jotId: prediction
        }),
        markJotTrue: (jotId: string, resultKnownTimestamp: Date) => dispatch({type: Actions.USER_MARKED_JOT_TRUE, jotId: jotId,
            resultKnownTimestamp: resultKnownTimestamp}),
        markJotFalse: (jotId: string, resultKnownTimestamp: Date) => dispatch({type: Actions.USER_MARKED_JOT_FALSE, jotId: jotId,
            resultKnownTimestamp: resultKnownTimestamp}),
        moreJots: (userId: string, jotCount: number) => dispatch({
            type: Actions.PROFILE_FEED_FETCH_MORE_JOTS,
            userId: userId,
            jotCount: jotCount
        }),
        refreshJots: (userId: string, newTimestamp: Actions.Timestamp, oldTimestamp: Actions.Timestamp) => dispatch({
            type: Actions.PROFILE_FEED_LIVE_UPDATE_POSITION,
            userId: userId,
            newestTimestamp: newTimestamp,
            oldestTimestamp: oldTimestamp
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
