import React from 'react';

const Startup = (props: any) => {
    return (
        <div className="Startup">
        </div>
    );
};

export default (Startup);
