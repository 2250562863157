import 'firebase/firestore';

import * as actionTypes from "./Actions";
import * as paths from "../PagePathConstants";
import {ActionTypes} from "./Actions";
import {
    AppState,
    IJot,
    IUserDetails,
    JotId,
    UserId,
    FeedId,
    IUserIdentifier,
    UserIdSet,
    ISuggestion
} from "./DataInterfaces";
import * as Actions from "./Actions";
import firebase from 'firebase/compat/app';


const initialState: AppState = {
    userLoggedIn: false,
    pastVotes: new Map<JotId, boolean>(),
    currentUserID: "",
    currentRootPath: "",
    pageOwner: "",
    selectedSource: "",
    selectedHashtag: "",
    users: new Map<UserId, IUserDetails>(),
    userIdentifiers: new UserIdSet<IUserIdentifier>(),
    jots: new Map<JotId, IJot>(),
    feedJotIds: new Map<FeedId, Map<JotId, JotId>>(),
    savingJot: false,
    savingBio: false,
    loggingIn: false,
    sourceMatches: new Array<ISuggestion>(),
    hashtagMatches: new Array<ISuggestion>()
};

export const MainFeedId = "main";
export const ProfileFeedId = "profile-";
export const SourceFeedId = "source-";
export const HashtagFeedId = "hashtag-";

const reducer = (state = initialState, action: ActionTypes) => {

    function updateFeedJotIds(feedID: string, jots: IJot[]) {
        let feedJots: Map<JotId, JotId> = state.feedJotIds.get(feedID) ?? new Map<JotId, JotId>()

        if (feedJots.size === 0)
        {
            jots.forEach((jot) => {
                if (!feedJots.has(jot.id)) {
                    feedJots.set(jot.id, jot.id)
                }
            })

            return feedJots
        }

        if (jots.length === 0)
        {
            return feedJots
        }

        const firstOldJotId = feedJots.values().next().value
        const firstOldJot = state.jots.get(firstOldJotId);

        if (firstOldJot)
        {
            const firstOldTime = firstOldJot.createdTimestamp
            if (jots[0].createdTimestamp > firstOldTime)
            {
                let rebuiltFeed = new Map<JotId, JotId>()
                jots.forEach((jot) => {
                    if (!rebuiltFeed.has(jot.id)) {
                        rebuiltFeed.set(jot.id, jot.id)
                    }
                })

                feedJots.forEach((jotId) => {
                    if (!rebuiltFeed.has(jotId)) {
                        rebuiltFeed.set(jotId, jotId)
                    }
                })

                return rebuiltFeed
            }
        }

        jots.forEach((jot) => {
            // @ts-ignore
            if (!feedJots.has(jot.id)) {
                // @ts-ignore
                feedJots.set(jot.id, jot.id)
            }
        })

        return feedJots
    }

    function updateJotInfo(newJots: IJot[]) {
        let existingJots = state.jots
        newJots.forEach((jot) => {
            existingJots.set(jot.id, jot)
        })
        return existingJots
    }

    function getAllLocalUniqueUserIds(newJots: readonly IJot[], knownUsers: Set<IUserIdentifier>) {
        // TODO: Remove this code, tempory feature to allow users to see other profiles from the profiles window
        // search for any users to present profiles of

        let newUsers = new UserIdSet(knownUsers)
        newJots.forEach((jot) => {
            const possibleUnknownUserID = jot.creator;
            newUsers.add({userId: possibleUnknownUserID, userName: jot.creatorName})
        });

        return newUsers
    }

    switch (action.type) {
        case actionTypes.USER_LOGIN: {
            return {
                ...state,
                userLoggedIn: true
            }
        }
        case actionTypes.BACKEND_CURRENT_USER_VOTES: {
            if (action.votes.size === 0) return state

            let updatedPastVotes = action.votes
            const oldVotes = state.pastVotes as Map<JotId, boolean>
            if (oldVotes.size)
            {
                updatedPastVotes = new Map([...oldVotes.entries(), ...updatedPastVotes.entries()])
            }

            return {
                ...state,
                pastVotes: updatedPastVotes,
            };
        }
        case actionTypes.USER_LOGOUT: {
            return initialState;
        }
        case actionTypes.LOGGING_IN: {
            return {
                ...state,
                loggingIn: true
            };
        }
        case actionTypes.LOG_IN_FAILED: {
            return {
                ...state,
                loggingIn: false
            };
        }
        case actionTypes.ROUTE_LOCATION_DID_UPDATE: {
            const currentPath = action.location.pathname;

            const startOfPath = currentPath.split('/')[1];
            let newState = {...state, currentRootPath: `/${startOfPath}`}

            switch (newState.currentRootPath) {
                case paths.ProfilePage: {
                    const userID = currentPath.split('/')[2];
                    if (userID) {
                        return {...newState, currentRootPath: currentPath, pageOwner: userID}
                    } else {
                        return {...newState, pageOwner: state.currentUserID}
                    }
                }
                case paths.SourceJotsPage: {
                    const source = currentPath.split('/')[2];
                    return {...newState, selectedSource: source}
                }
                case paths.HashtagJotsPage: {
                    const hashtag = currentPath.split('/')[2];
                    return {...newState, selectedHashtag: hashtag}
                }
            }

            return newState
        }
        case actionTypes.Current_USER_DETAILS: {
            const currentUserID = action.userDetails.userId;

            if (currentUserID == null) return state;

            return {
                ...state,
                currentUserID: currentUserID,
                users: (state.users.set(currentUserID, action.userDetails)),
                userLoggedIn: true
            };
        }
        case actionTypes.USER_DETAILS: {
            const userID = action.userDetails.userId;

            return {
                ...state,
                users: (state.users.set(userID, action.userDetails))
            };
        }
        case actionTypes.SAVING_BIO: {
            return {...state, bioSaving: true};
        }
        case actionTypes.BIO_SAVED: {
            return {...state, bioSaving: false};
        }
        case actionTypes.SAVING_BIO_FAILED: {
            return {...state, bioSaving: false};
        }
        case actionTypes.SAVING_USER_PREDICTION: {
            return  {...state, savingJot: true};
        }
        case actionTypes.ADDING_PREDICTION_FAILED: {
            return {...state, savingJot: false};
        }
        case actionTypes.USER_ADDED_PREDICTION: {
            return  {...state, savingJot: false};
        }
        case actionTypes.CURRENT_USER_CREATED_JOTS: {

            const newJots = action.jots;
            let knownUsers = state.userIdentifiers;

            const feedID = ProfileFeedId + action.userId
            const updatedUsers = getAllLocalUniqueUserIds(newJots, knownUsers);
            const updatedFeed = updateFeedJotIds(feedID, newJots)
            const updatedJots = updateJotInfo(newJots)

            return {...state, userIdentifiers: updatedUsers, updatedFeed: state.feedJotIds.set(feedID, updatedFeed), jots: updatedJots}
        }
        case actionTypes.SOURCE_JOTS: {

            const newJots = action.jots;

            const feedID = SourceFeedId + action.source
            const updatedFeed = updateFeedJotIds(feedID, newJots)
            const updatedJots = updateJotInfo(newJots)

            return {...state, updatedFeed: state.feedJotIds.set(feedID, updatedFeed), jots: updatedJots}
        }
        case actionTypes.HASHTAG_JOTS: {
            const newJots = action.jots;

            const feedID = HashtagFeedId + action.hashtag
            const updatedFeed = updateFeedJotIds(feedID, newJots)
            const updatedJots = updateJotInfo(newJots)

            return {...state, updatedFeed: state.feedJotIds.set(feedID, updatedFeed), jots: updatedJots}
        }
        case actionTypes.MAIN_FEED_JOTS_RETURNED: {
            const newJots = action.jots;
            let knownUsers = state.userIdentifiers;

            const updatedUsers = getAllLocalUniqueUserIds(newJots, knownUsers);
            const updatedFeed = updateFeedJotIds(MainFeedId, newJots)
            const updatedJots = updateJotInfo(newJots)

            return {...state, userIdentifiers: updatedUsers, updatedFeed: state.feedJotIds.set(MainFeedId, updatedFeed), jots: updatedJots};
        }
        case Actions.USER_MARKED_JOT_TRUE: {
            const jotId = action.jotId;
            const jots = state.jots;
            const jot = jots.get(jotId);
            if (jot)
            {
                jot.closedTimestamp = firebase.firestore.Timestamp.now();
                jot.outcome = true;
                jot.predictionClosed = true;
                jots.set(jotId, jot);
                return {...state, jots: jots};
            }

            return state
        }
        case Actions.USER_MARKED_JOT_FALSE: {
            const jotId = action.jotId;
            const jots = state.jots;
            const jot = jots.get(jotId);
            if (jot)
            {
                jot.closedTimestamp = firebase.firestore.Timestamp.now();
                jot.outcome = false;
                jot.predictionClosed = false;
                jots.set(jotId, jot);
                return {...state, jots: jots};
            }

            return state
        }
        case actionTypes.USER_AGREED_WITH_PREDICTION: {
            const jotId = action.jotId
            let newVotes = state.pastVotes
            newVotes.set(jotId, true);

            const jots = state.jots;
            let jot = jots.get(jotId);
            if (jot)
            {
                jot = {...jot, agree: jot.agree + 1}
                jots.set(jotId, jot);
                return {...state, pastVotes: newVotes, jots: jots};
            }

            return {...state, pastVotes: newVotes};
        }
        case actionTypes.USER_DISAGREED_WITH_PREDICTION: {
            const jotId = action.jotId
            let newVotes = state.pastVotes
            newVotes.set(jotId, false);

            const jots = state.jots;
            let jot = jots.get(jotId);
            if (jot)
            {
                jot = {...jot, disagree: jot.disagree + 1}
                jots.set(jotId, jot);
                return {...state, pastVotes: newVotes, jots: jots};
            }

            return {...state, pastVotes: newVotes};
        }
        case actionTypes.FOUND_SOURCE_MATCHES: {
            return {...state, sourceMatches: action.sourceNames}
        }
        case actionTypes.FOUND_HASHTAG_MATCHES: {
            return {...state, hashtagMatches: action.hashtagNames}
        }
        default:
            return state;
    }
};

export default reducer;
