import firebase from 'firebase/compat/app';
import 'firebase/compat/performance';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration

let firebaseConfig

// if (process.env.REACT_APP_RELEASE_ENVIRONMENT !== 'production')
// {
//      // staging
//      firebaseConfig = {
//         apiKey: "AIzaSyCYzrARg8Qjjfj6q4naD49c9m7XkCc4LQw",
//         authDomain: "tj-labs.firebaseapp.com",
//         databaseURL: "https://tj-labs.firebaseio.com",
//         projectId: "tj-labs",
//         storageBucket: "tj-labs.appspot.com",
//         messagingSenderId: "803534939901",
//         appId: "1:803534939901:web:1da9b10739f4c883308094",
//         measurementId: "G-GMZMJ8Y5B7"
//     }
// }
// else
// {
    // production
     firebaseConfig = {
        apiKey: "AIzaSyDWFTmpsBRMTug8wquu0XZX5yEJrvzATws",
        authDomain: "trujotlab-production.firebaseapp.com",
        databaseURL: "https://trujotlab-production.firebaseio.com",
        projectId: "trujotlab-production",
        storageBucket: "trujotlab-production.appspot.com",
        messagingSenderId: "264180333440",
        appId: "1:264180333440:web:dc5dc20bb8cbcd9fd35f65",
        measurementId: "G-9KS8TDLJF6"
    };

// }


// Initialize Firebase
const AppBackend = firebase.initializeApp(firebaseConfig);

// Initialize performance
firebase.performance();
export const analytics = getAnalytics(AppBackend);

export default AppBackend;
