import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppBackend from "./Store/Backend/BackendSetup";
import './index.css';
import App from './App';
import {applyMiddleware, compose, createStore} from "redux";
import reducer from "./Store/Reducer";
import thunk from "redux-thunk";
import BackendMiddleware from "./Store/BackendMiddleware";
import {Provider} from "react-redux";
import {createBrowserHistory} from "history";
import * as actionTypes from "./Store/Actions";
import {Router} from "react-router";

if (AppBackend)
{
    // backend service active
}
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, /* preloadedState, */ composeEnhancers(applyMiddleware(thunk, BackendMiddleware)));

const history = createBrowserHistory();
history.listen(location => store.dispatch({type: actionTypes.ROUTE_LOCATION_DID_UPDATE, location: location}))
// Manually dispatch at the start to ensure the store knows the first page
store.dispatch({type: actionTypes.ROUTE_LOCATION_DID_UPDATE, location: history.location});

ReactDOM.render(<Provider store={store}>
                    <Router history={history}>
                        <App history={history}/>
                    </Router>
                </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
