import React, {ChangeEvent, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {AppState, IUserDetails} from "../../Store/DataInterfaces";
import * as ActionFactory from "../../Store/ActionFactory";
/// <reference types="@types/googlemaps" />
import * as paths from "../../PagePathConstants";


import './UserSignUpDetails.css';

const UserSignUpDetails = (props: any) => {

    interface IState {
        userDetails: IUserDetails
        uploading: boolean;
        file: File | undefined;
    }

    // const geocoder = new google.maps.Geocoder();
    const [state, setState] = useState<IState>({
        userDetails: {userName: "", userId: "", score: 0, bio: ""},
        uploading: false,
        file: undefined
    });

    useEffect(() =>
        {
            if (props.userProfileSetup)
            {
                props.history.push(paths.MainPagePath);
            }
        },
        [props.userProfileSetup]);

    const userNameValid = (name: string) => {
        const letters = /^[0-9a-zA-Z ]+$/;
        return (name.length > 0) && letters.test(name);
    };


    const userName = (event: ChangeEvent<HTMLInputElement>) =>
    {
        const input: string = event.currentTarget.value? event.currentTarget.value: "";
        if (!userNameValid(input))
        {
            // alert('Please input alphanumeric characters only');
        }

        // Intentionally set value either way. we should always try to upload what the user sees
        setState({...state, userDetails: {...state.userDetails, userName: input}})
    };

    const save = async () => {

        if (!state || state.uploading) return;
        const userName = state.userDetails.userName;

        // TODO: stop using user name check for skills
        if (userNameValid(userName)) {
            setState({...state, uploading: true});

            // upload user data
            // TJDataBase.uploadUserProfile(state.userDetails);
            props.saveUserSignupDetails(state.userDetails);

            props.history.push(paths.MainPagePath);
        }
    };

    return (
        <div className={"signupOuterWrapper"}>
            <div className="UserSignupDetails">
                <div className={"detailsLeft"}>
                    <h1>Welcome to TJ Labs!</h1>
                    <div className={"textButtonWrapper"}>
                        <h2>Add a name so that others know who you are :)</h2>
                        <form className={"detailsForm"} onSubmit={() => save()}>
                            <input id = "user-name-field"
                                   type = "text"
                                   placeholder = "user name"
                                   onChange={userName}/>
                            <br/>
                            <input type = "submit" value = "Save"/>
                        </form>
                    </div>
                </div>
                <div className={"detailsRignt"}>
                    <img className={"welcome-decal"} src="img/welcome-decal.svg" alt="Welcome page decale"/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {

    let stateProps = {
        userLogInState: state.userLoggedIn,
        userProfileSetup: false,
    }

    if (state.currentUserID !== "")
    {
        const userDetails = state.users.get(state.currentUserID)
        if (userDetails)
        {
            stateProps.userProfileSetup = userDetails.userName !== "";
        }
    }

    return stateProps
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        saveUserSignupDetails: (userDetails: IUserDetails) => dispatch(ActionFactory.saveUserDetails(userDetails)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignUpDetails);
